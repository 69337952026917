import React from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import { ScrollRotate } from 'react-scroll-rotate';

import borderGame from "../assets/Png/GraphicFrame.png";
import StoneRuneCircle from '../assets/Png/GraphicStoneCircle.png';

import Header from './../components/global/header';
import Footer from './../components/global/footer';

function Imprint() {
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const isBiggerMobile = useMediaQuery({ query: '(min-width: 992px)' })

  return (
    <>
      <Header/>
        <div className="imprint-page">
          <ReactBootstrap.Container id="imprint">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="text-center">
                  <h2>
                    {t('imprint')}
                  </h2>
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="text-left">
                  <h2>
                    {t('company_info')}
                  </h2>
                  <div>
                    <p>Nurogames GmbH</p>
                    <p>Schaafenstr. 25</p>
                    <p>50676 {t('city')} / {t('country')}</p>
                    <p>www.nurogames.com</p>
                    <p>info@nurogames.com</p>
                    <p>Tel.: +49 221 398 80840</p>
                    <p>Fax.: +49 221 398 8004</p>
                  </div>
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="text-left">
                  <h2>
                    {t('person_in_charge')}
                  </h2>
                  <div>
                    Jens Piesk
                  </div>
                </div>
                <div className="text-left">
                  <h2>
                    {t('executive_director')}
                  </h2>
                  <div>
                    Jens Piesk
                  </div>
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="text-left">
                  <h2>
                    {t('situs_and_court_of_registry')}
                  </h2>
                  <div>
                    <div>
                      {t('trade_register_number')}: HRB 57760,
                    </div>
                    <div>
                      {t('local_court')} {t('city')},
                    </div>
                    <div>
                      {t('vat_number')}: DE249556947
                    </div>
                  </div>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
          {
            isMobile ? (
              <></>
            ) : (
              <>
                <div className="corner-stone-rune-imprint">
                  <ScrollRotate from={0} to={360}>
                    <img src={StoneRuneCircle} alt="Stone Runes"/>
                  </ScrollRotate>
                </div>
                {/*<div className="frame-game imprint-border">
                  <img src={borderGame} alt="Border"/>
                </div>*/}
              </>
            )
          }
        </div>
      <Footer/>
    </>
  );
};

export default Imprint;
