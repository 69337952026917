import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import lottie from "lottie-web";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { ScrollRotate } from 'react-scroll-rotate';
import axios from 'axios';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';

import animation from './../../assets/Animations/AnimationSparkle.json';
import animationTwo from './../../assets/Animations/AnimationLightning.json';
import animationMain from './../../assets/PlayerWithEffects/PlayerWithEffects.json';
//import animationVideo from './../../assets/PlayerWithEffects/PlayerWithEffects.mp4';

import borderGame from './../../assets/Png/GraphicFrame.png';
import CharacterPicture from './../../assets/Png/GraphicPlayer.png';
import StoneRuneCircle from "../../assets/Png/GraphicStoneCircle.png";
import TeaserButton from "./../../assets/svg/teaserButton.svg";

function MainTeaser(){
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successfulRegister, setSuccessfulRegister] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [vrHeadset, setVRHeadset] = useState('');
  const [steamName, setSteamName] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const isBiggerMobile = useMediaQuery({ query: '(min-width: 992px)' })

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('.teaser-image-eight'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationMain,
      rendererSettings: {
        filterSize: {
          width: '200%',
          height: '200%',
          x: '-50%',
          y: '-50%',
        }
      }
    })
    /*lottie.loadAnimation({
      container: document.querySelector('.teaser-image-eleven'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationTwo,
      rendererSettings: {
        filterSize: {
          width: '200%',
          height: '200%',
          x: '-50%',
          y: '-50%',
        }
      }
    })
    lottie.loadAnimation({
      container: document.querySelector('.teaser-image-twelve'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationTwo,
      rendererSettings: {
        filterSize: {
          width: '200%',
          height: '200%',
          x: '-50%',
          y: '-50%',
        }
      }
    })*/
  }, []);

  useEffect(() => {
    if(isBiggerMobile){
      (function() {
        window.addEventListener('scroll', function(event) {
          let depth, i, layer, layers, len, movement, topDistance, translate3d;
          topDistance = this.pageYOffset;
          layers = document.querySelectorAll("[data-type='parallax']");
          for (i = 0, len = layers.length; i < len; i++) {
            layer = layers[i];
            depth = layer.getAttribute('data-depth');
            movement = -(topDistance * depth);
            translate3d = 'translate3d(0, ' + movement + 'px, 0)';
            layer.style['-webkit-transform'] = translate3d;
            layer.style['-moz-transform'] = translate3d;
            layer.style['-ms-transform'] = translate3d;
            layer.style['-o-transform'] = translate3d;
            layer.style.transform = translate3d;
            if(i === 9 || i === 10){
              layer.style['-webkit-transform'] = 'translate3d(0, ' + movement + 'px, 0) rotate(5deg)';
              layer.style['-moz-transform'] = 'translate3d(0, ' + movement + 'px, 0) rotate(5deg)';
              layer.style['-ms-transform'] = 'translate3d(0, ' + movement + 'px, 0) rotate(5deg)';
              layer.style['-o-transform'] = 'translate3d(0, ' + movement + 'px, 0) rotate(5deg)';
              layer.style.transform = 'translate3d(0, ' + movement + 'px, 0) rotate(5deg)';
            }
          }
        });

      }).call(this);
    }
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('.rune_bender_character'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationMain,
      rendererSettings: {
        filterSize: {
          width: '200%',
          height: '200%',
          x: '-50%',
          y: '-50%',
        }
      }
    })
    /*lottie.loadAnimation({
      container: document.querySelector('.sparkles-animate'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        filterSize: {
          width: '200%',
          height: '200%',
          x: '-50%',
          y: '-50%',
        }
      }
    })
    lottie.loadAnimation({
      container: document.querySelector('.lightnings-small-animate'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationTwo,
      rendererSettings: {
        filterSize: {
          width: '200%',
          height: '200%',
          x: '-50%',
          y: '-50%',
        }
      }
    })
    lottie.loadAnimation({
      container: document.querySelector('.lightnings-big-animate'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationTwo,
      rendererSettings: {
        filterSize: {
          width: '00%',
          height: '200%',
          x: '-50%',
          y: '-50%',
        }
      }
    })
    lottie.loadAnimation({
      container: document.querySelector('.lightnings-small-two-animate'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationTwo,
      rendererSettings: {
        filterSize: {
          width: '200%',
          height: '200%',
          x: '-50%',
          y: '-50%',
        }
      }
    })
    lottie.loadAnimation({
      container: document.querySelector('.lightnings-big-two-animate'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationTwo,
      rendererSettings: {
        filterSize: {
          width: '200%',
          height: '200%',
          x: '-50%',
          y: '-50%',
        }
      }
    })*/
  }, []);

  const handleChangeName = e => setName(e.target.value);
  const handleChangeEmail = e => setEmail(e.target.value);
  const handleChangeVRHeadset = e => setVRHeadset(e.target.value);
  const handleChangeSteamName = e => setSteamName(e.target.value);

  const validationRegistration = (e) => {
    e.preventDefault();

    const url = 'https://backend.runebender.com/beta-test';

    const data = {
      name: name,
      email: email,
      vr_headset: vrHeadset,
      steam_nickname: steamName
    };

    // fetch data and validate
    axios
      .post(url, data)
      .then((response) => {
        if(response.status === 200){
          setSuccessfulRegister(true);

          setTimeout(() => {
            setSuccessfulRegister(false);
            setIsModalOpen(false);
            setName('');
            setEmail('');
            setVRHeadset('');
            setSteamName('');
          }, 3000);
          /*
          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("login-email")[0].classList.remove("error-field");
          document.getElementsByClassName("error-text")[1].style.display = "none";
          document.getElementsByClassName("login-password")[0].classList.remove("error-field");
          */
        } else {
          console.log("Error: Access Denied");
        }
      })
      .catch(e => {
        const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        /*
        if(!email || !regexEmail.test(String(email).toLowerCase())){
          document.getElementsByClassName("error-text")[0].style.display = "block";
          document.getElementsByClassName("error-text")[0].innerHTML = "Please enter a valid e-mail.";
          document.getElementsByClassName("login-email")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[0].style.display = "none";
          document.getElementsByClassName("login-email")[0].classList.remove("error-field");
        }

        if(!password){
          document.getElementsByClassName("error-text")[1].style.display = "block";
          document.getElementsByClassName("error-text")[1].innerHTML = "Please enter a valid password.";
          document.getElementsByClassName("login-password")[0].classList.add("error-field");
        } else {
          document.getElementsByClassName("error-text")[1].style.display = "none";
          document.getElementsByClassName("login-password")[0].classList.remove("error-field");
        }

        document.getElementsByClassName("tbs--form--main--error")[0].innerHTML = "Invalid credentials.\nThis account does not exist.";
        document.getElementsByClassName("tbs--form--main--error")[0].style.display = "block";
        document.getElementsByClassName("login-email")[0].classList.add("error-field");
        document.getElementsByClassName("login-password")[0].classList.add("error-field");
        */
        console.log(e)
      })
  };

  const RegistrationBetatestSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, 'Too Short!')
      .required('Name is required.'),
    email: Yup.string()
      .min(5, 'Too Short!')
      .email('Invalid email')
      .required('E-Mail is required'),
    vr_headset: Yup.string()
      .min(3, 'Too Short!')
      .required('VR headset is required.'),
    steam_nickname: Yup.string()
      .min(2, 'Too Short!')
      .required('Steam nickname is required.')
  });

  const openModal = () => {
    setName('');
    setEmail('');
    setVRHeadset('');
    setSteamName('');
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setName('');
    setEmail('');
    setVRHeadset('');
    setSteamName('');
    setIsModalOpen(false);
  }

  const checkMenu = () => {
    const openMenu = document.querySelector(".navbar-collapse.collapse.show");

    if(typeof(openMenu) != 'undefined' && openMenu != null){
      document.querySelector(".navbar-toggler").click();

      setIsMobileMenuOpen(false);

      setTimeout(() => {
        window.history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
      }, 5);
    } else {
      setTimeout(() => {
        window.history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
      }, 5);
    }

    document.getElementById("trailer").scrollIntoView();
  }

  return (
    <>
      {
        isBiggerMobile ? (
          <div className="teaser-animate" id="main-teaser-big">
            <div className='teaser-image-one layer' data-depth='0.10' data-type='parallax'></div>
            <div className='teaser-image-two layer' data-depth='0.20' data-type='parallax'></div>
            <div className='teaser-image-five layer' data-depth='0.30' data-type='parallax'></div>
            <div className='teaser-image-six layer' data-depth='0.40' data-type='parallax'></div>
            <div className='teaser-image-three layer' data-depth='0.60' data-type='parallax'></div>
            <div className='teaser-image-four layer' data-depth='0.80' data-type='parallax'></div>
            {/*<div className='teaser-image-seven layer' data-depth='0.90' data-type='parallax'></div>*/}
            <div className='teaser-image-nine layer' data-depth='0.95' data-type='parallax'></div>
            <div className='teaser-image-ten layer' data-depth='0.96' data-type='parallax'></div>
            <div className='teaser-image-eight layer' data-depth='0.98' data-type='parallax'></div>
            {/*<div className='teaser-image-eleven layer' data-depth='1.00' data-type='parallax'></div>
            <div className='teaser-image-twelve layer' data-depth='1.00' data-type='parallax'></div>*/}
            {/*<div class="frame-game">
              <img src={borderGame} alt="Border"/>
            </div>*/}
            {/*<div className="corner-stone-rune-main">
              <ScrollRotate>
                <img src={StoneRuneCircle} alt="Stone Runes"/>
              </ScrollRotate>
            </div>*/}
            <div className="button-container-main">
              <div className="button-trailer" onClick={checkMenu}>
                <img src={TeaserButton} alt="Teaser button"/>
                <span className={
                  localStorage.getItem('language') === 'de'
                    ? 'german-text'
                    : localStorage.getItem('language') === 'en'
                      ? 'other-text'
                      : localStorage.getItem('language') === 'fr'
                        ? 'rest-text-one'
                        : localStorage.getItem('language') === 'it'
                          ? 'other-text'
                          : localStorage.getItem('language') === 'es'
                            ? 'spanish-text'
                            : ''
                }>
                  {t('trailer')}
                </span>
              </div>
              <div className="button-wishlist">
                <a href="https://store.steampowered.com/app/2387750/Rune_Bender_VR" alt="Rune Bender VR - Steam website" target="_blank">
                  <img src={TeaserButton} alt="Teaser button"/>
                  <span className={
                    localStorage.getItem('language') === 'de'
                      ? 'german-text-two'
                      : localStorage.getItem('language') === 'en'
                        ? 'other-text'
                        : localStorage.getItem('language') === 'fr'
                          ? 'rest-text'
                          : localStorage.getItem('language') === 'it'
                            ? 'rest-text'
                            : localStorage.getItem('language') === 'es'
                              ? 'rest-text-two'
                              : ''
                  }>
                    {t('get-it')}
                  </span>
                </a>
              </div>
              <div className="button-betatest">
                <a href="https://store.steampowered.com/app/2997050/Rune_Bender_VR_Demo/" alt="Rune Bender VR Demo" target="_blank">
                  <img src={TeaserButton} alt="Teaser button"/>
                  <span className={'other-text'}>
                    {t('demo')}
                  </span>
                </a>
              </div>
            </div>
            {/*<div className="button-betatest" onClick={openModal}>
              <img src={TeaserButton} alt="Teaser button"/>
              <span className={localStorage.getItem('language') === 'de' ? 'german-text' : ''}>
                {t('betatester')}
              </span>
            </div>*/}
            {/*<div className="corner-stone-rune-main">
              <ScrollRotate>
                <img src={StoneRuneCircle} alt="Stone Runes"/>
              </ScrollRotate>
            </div>*/}
          </div>
        ) : (
          <>
            <div className="teaser-main-mobile">
              <div className="game-background"></div>
              <div className="button-trailer" onClick={checkMenu}>
                <img src={TeaserButton} alt="Teaser button"/>
                <span className={
                  localStorage.getItem('language') === 'de'
                    ? 'german-text'
                    : localStorage.getItem('language') === 'en'
                      ? 'rest-text-other'
                      : localStorage.getItem('language') === 'fr'
                        ? 'rest-text-other-six'
                        : localStorage.getItem('language') === 'it'
                          ? 'rest-text-other-five'
                          : localStorage.getItem('language') === 'es'
                            ? 'rest-text-other-four'
                            : ''
                }>
                  {t('trailer')}
                </span>
              </div>
              <div className="button-wishlist">
                <a href="https://store.steampowered.com/app/2387750/Rune_Bender_VR" alt="Rune Bender VR - Steam website" target="_blank">
                  <img src={TeaserButton} alt="Teaser button"/>
                  <span className={
                    localStorage.getItem('language') === 'de'
                      ? 'german-text-two'
                      : localStorage.getItem('language') === 'en'
                        ? 'rest-text-other-two'
                        : localStorage.getItem('language') === 'fr'
                          ? 'rest-text'
                          : localStorage.getItem('language') === 'it'
                            ? 'rest-text'
                            : localStorage.getItem('language') === 'es'
                              ? 'rest-text-other-three'
                              : ''
                  }>
                    {t('get-it')}
                  </span>
                </a>
              </div>
              <div className="button-betatest">
                <a href="https://store.steampowered.com/app/2997050/Rune_Bender_VR_Demo/" alt="Rune Bender VR Demo" target="_blank">
                  <img src={TeaserButton} alt="Teaser button"/>
                  <span className={'other-text'}>
                    {t('demo')}
                  </span>
                </a>
              </div>
              {/*<div className="button-betatest" onClick={openModal}>
                  <img src={TeaserButton} alt="Teaser button"/>
                  <span className={localStorage.getItem('language') === 'de' ? 'german-text' : ''}>
                    {t('betatester')}
                  </span>
              </div>*/}
              <div className="rune_bender_character">
                {/*<div className="lightnings-animate">
                  <div className="lightnings-small-animate"></div>
                  <div className="lightnings-big-animate"></div>
                  <div className="lightnings-small-two-animate"></div>
                  <div className="lightnings-big-two-animate"></div>
                <div className="character-image">
                  <img src={CharacterPicture} alt="Character" />
                </div>
                <div className="sparkles-animate"></div>*/}
              </div>
            </div>
          </>
        )
      }
      <ReactBootstrap.Modal
        show={isModalOpen}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={closeModal}
      >
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
            {t('betatester')} {t('registration')}
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body>
          <div>
            {t('betatest-text-one')}
          </div>
          <div>
            {t('betatest-text-two')}
          </div>
          <div id="registration-betatest">
            <Formik
              initialValues={{
                name: '',
                email: '',
                vr_headset: '',
                steam_nickname: '',
              }}
              validationSchema={RegistrationBetatestSchema}
              onSubmit={values => {
                console.log(values.text);
              }}
            >
              {({ errors, touched }) => (
                <Form onSubmit={validationRegistration}>
                  <div>
                    <label htmlFor="name">
                      {t('name')}
                    </label>
                  </div>
                  <div id="name-register">
                    <Field type="text" className={`register-name`} name="name" id="name" placeholder={t('enter-name')} value={name} onChange={handleChangeName}/>
                  </div>
                  <div className="error-text">
                    {/*
                      errors.name && touched.name
                      ?
                        errors.name
                      :
                        null
                    */}
                    {<ErrorMessage name="name">
                      {errors.name}
                    </ErrorMessage>}
                  </div>
                  <div>
                    <label htmlFor="email">
                      {t('email')}
                    </label>
                  </div>
                  <div id="email-register">
                    <Field type="email" className={`register-email`} name="email" id="email" placeholder={t('enter-email')} value={email} onChange={handleChangeEmail}/>
                  </div>
                  <div className="error-text">
                    {/*
                      errors.email && touched.email
                      ?
                        errors.email
                      :
                        null
                    */}
                    {<ErrorMessage name="email">
                      {errors.email}
                    </ErrorMessage>}
                  </div>
                  <div>
                    <label htmlFor="vr_headset">
                      {t('vr_headset')}
                    </label>
                  </div>
                  <div id="vrheadset-register">
                    <Field type="text" className={`register-vrheadset`} name="vr_headset" id="vr_headset" placeholder={t('enter-vr_headset')} value={vrHeadset} onChange={handleChangeVRHeadset}/>
                  </div>
                  <div className="error-text">
                    {/*
                      errors.vr_headset && touched.vr_headset
                      ?
                        errors.vr_headset
                      :
                        null
                    */}
                    {<ErrorMessage name="vrheadset">
                      {errors.vrheadset}
                    </ErrorMessage>}
                  </div>
                  <div>
                    <label htmlFor="steam_nickname">
                      {t('steam_nickname')}
                    </label>
                  </div>
                  <div id="steam_nickname-register">
                    <Field type="text" className={`register-steamname`} name="steam_nickname" id="steam_nickname" placeholder={t('enter-steam_nickname')} value={steamName} onChange={handleChangeSteamName}/>
                  </div>
                  <div className="error-text">
                    {/*
                      errors.steam_nickname && touched.steam_nickname
                      ?
                        errors.steam_nickname
                      :
                        null
                    */}
                    {<ErrorMessage name="steamname">
                      {errors.steam_nickname}
                    </ErrorMessage>}
                  </div>
                  {
                    successfulRegister ? (
                      <>
                        <div className="register-success">
                          {t('register-successful')}
                        </div>
                      </>
                    ) : (
                      <></>
                    )
                  }
                  <div className="text-center button--sign">
                    <ReactBootstrap.Button
                      type="submit"
                      disabled={name.length >= 5 && email.length >= 5 && vrHeadset.length >= 3 && steamName.length >= 2 ? false : true}>
                      {t('registration-normal')}
                    </ReactBootstrap.Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ReactBootstrap.Modal.Body>
      </ReactBootstrap.Modal>
    </>
  )
}

export default MainTeaser;
