import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import { ScrollRotate } from 'react-scroll-rotate';
import Scrollspy from 'react-scrollspy';

import MainTeaser from "../components/container/mainTeaser";
import SecondTeaser from "../components/container/secondTeaser";
import ThirdTeaser from "../components/container/thirdTeaser";
import FourthTeaser from "../components/container/fourthTeaser";
import FivthTeaser from "../components/container/fivthTeaser";

import Header from './../components/global/header';
import Footer from './../components/global/footer';

import StoneRuneCircle from '../assets/Png/GraphicStoneCircle.png';
import borderGame from '../assets/Png/GraphicFrame.png';
import VerticalMenu from '../assets/svg/verticalMenu/borderMenu.svg';
import ActivePoint from '../assets/svg/mobile/activePoint.svg';
import UnactivePoint from '../assets/svg/mobile/unactivePoint.svg';

function Main(){
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const isBiggerMobile = useMediaQuery({ query: '(min-width: 992px)' })

  const [isHome, setIsHome] = useState(false);
  const [isPlatform, setIsPlatform] = useState(false);
  const [isTrailer, setIsTrailer] = useState(false);
  const [isScreenshots, setIsScreenshots] = useState(false);
  const [isDescription, setIsDescription] = useState(false);

  const scrollEffectNavigation = () => {
    const listElement = document.querySelectorAll(".vertical-slider-menu > div > ul > div");
    if (listElement[0].classList.contains('is-current')) {
      setIsHome(true);
      setIsPlatform(false);
      setIsTrailer(false);
      setIsScreenshots(false);
      setIsDescription(false);
    }
    if (listElement[1].classList.contains('is-current')) {
      setIsHome(false);
      setIsPlatform(true);
      setIsTrailer(false);
      setIsScreenshots(false);
      setIsDescription(false);
    }
    if (listElement[2].classList.contains('is-current')) {
      setIsHome(false);
      setIsPlatform(false);
      setIsTrailer(true);
      setIsScreenshots(false);
      setIsDescription(false);
    }
    if (listElement[3].classList.contains('is-current')) {
      setIsHome(false);
      setIsPlatform(false);
      setIsTrailer(false);
      setIsScreenshots(true);
      setIsDescription(false);
    }
    if (listElement[4].classList.contains('is-current')) {
      setIsHome(false);
      setIsPlatform(false);
      setIsTrailer(false);
      setIsScreenshots(false);
      setIsDescription(true);
    }
  };

  const checkMenuSlider = () => {
    setTimeout(() => {
      window.history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
    }, 1000);
  };

  return (
    <>
      <Header/>
        {
          isMobile ? (
            <></>
          ) : (
            <>
              <div className="corner-stone-rune-main-one">
                <ScrollRotate from={0} to={360}>
                  <img src={StoneRuneCircle} alt="Stone Runes"/>
                </ScrollRotate>
              </div>
              {/*<div className="frame-game-normal">
                <img src={borderGame} alt="Border"/>
              </div>*/}
              <div className="vertical-slider-menu">
                <div className="main-border">
                  <img src={VerticalMenu} alt="Vertical menu border"/>
                </div>
                <div className="main-border-elements">
                  <Scrollspy items={['main-teaser-big', 'platforms', 'trailer', 'screenshots', 'description']} offset={0} onUpdate={scrollEffectNavigation} currentClassName="is-current">
                    <div className="element-one">
                      <a href="#main-teaser-big" onClick={checkMenuSlider}>
                        <img src={isHome ? ActivePoint : UnactivePoint} alt="Point"/>
                      </a>
                    </div>
                    <div className="element-two" onClick={checkMenuSlider}>
                      <a href="#platforms">
                        <img src={isPlatform ? ActivePoint : UnactivePoint} alt="Point"/>
                      </a>
                    </div>
                    <div className="element-three" onClick={checkMenuSlider}>
                      <a href="#trailer">
                        <img src={isTrailer ? ActivePoint : UnactivePoint} alt="Point"/>
                      </a>
                    </div>
                    <div className="element-four" onClick={checkMenuSlider}>
                      <a href="#screenshots">
                        <img src={isScreenshots ? ActivePoint : UnactivePoint} alt="Point"/>
                      </a>
                    </div>
                    <div className="element-five" onClick={checkMenuSlider}>
                      <a href="#description">
                        <img src={isDescription ? ActivePoint : UnactivePoint} alt="Point"/>
                      </a>
                    </div>
                  </Scrollspy>
                </div>
              </div>
            </>
          )
        }
        <MainTeaser/>
        <SecondTeaser/>
        <ThirdTeaser/>
        <FourthTeaser/>
        <FivthTeaser/>
      <Footer/>
    </>
  )
}

export default Main;
