import React from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import {ScrollRotate} from "react-scroll-rotate";

import borderGame from "../assets/Png/GraphicFrame.png";
import StoneRuneCircle from '../assets/Png/GraphicStoneCircle.png';

import Header from './../components/global/header';
import Footer from './../components/global/footer';
import German from './../components/policy/german';
import English from './../components/policy/english';

function PrivacyPolicy() {
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const isBiggerMobile = useMediaQuery({ query: '(min-width: 992px)' })

  return (
    <>
      <Header/>
      <div className="privacy-policy-page">
        <ReactBootstrap.Container id="privacy-policy">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
              <h2>
                {t('privacy-policy-title')}
              </h2>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                localStorage.getItem("language") == "" || localStorage.getItem("language") == null || localStorage.getItem("language") == "en" || localStorage.getItem("language") == "fr" || localStorage.getItem("language") == "it" || localStorage.getItem("language") == "es" ? (
                  <English/>
                ) : (
                  <German/>
                )
              }
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
        {
          isMobile ? (
            <></>
          ) : (
            <>
              <div className="corner-stone-rune-privacy">
                <ScrollRotate from={0} to={360}>
                  <img src={StoneRuneCircle} alt="Stone Runes"/>
                </ScrollRotate>
              </div>
              {/*<div className="frame-game privacy-border">
                <img src={borderGame} alt="Border"/>
              </div>*/}
            </>
          )
        }
      </div>
      <Footer/>
    </>
  );
};

export default PrivacyPolicy;
