import React, {useEffect} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import { ScrollRotate } from 'react-scroll-rotate';

import StoneRuneCircle from './../../assets/Png/GraphicStoneCircle.png';
import borderGame from "../../assets/Png/GraphicFrame.png";
import treeCharacter from "../../assets/Png/GraphicLeshy.png";
import treeCharacterObject from "../../assets/Png/GraphicLeshyCone.png";

function FivthTeaser(){
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const isBiggerMobile = useMediaQuery({ query: '(min-width: 992px)' })

  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="teaser-description">
        {
          isMobile ? (
            <></>
          ) : (
            <>
              <div className="teaser-description-character" data-depth='0.10' data-type='parallax'></div>
              <div className="teaser-description-character-cone" data-depth='0.20' data-type='parallax'></div>
            </>
          )
        }
        <div id="description">
          {
            isBiggerMobile ? (
              <>
                {/*<div className="corner-stone-rune-description">
                  <ScrollRotate>
                    <img src={StoneRuneCircle} alt="Stone Runes"/>
                  </ScrollRotate>
                </div>*/}
                {/*<div className="frame-game content-description">
                  <img src={borderGame} alt="Border"/>
                </div>*/}
              </>
            ) : (
              <></>
            )
          }
          <div className="description">
            {/*<div className="content-description">
              <img src={treeCharacter} alt="Tree character"/>
              <img src={treeCharacterObject} alt="Tree character cone"/>
            </div>*/}
            <ReactBootstrap.Container>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} className="text-left">
                  <h1>
                    {t('description')}
                  </h1>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xl={6} lg={6} md={12} sm={12} xs={12} className="text-left">
                  <div>
                    {t('description_text_one')}
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xl={6} lg={6} md={12} sm={12} xs={12} className="text-left">
                  <div>
                    {t('description_text_two')}
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xl={6} lg={6} md={12} sm={12} xs={12} className="text-left">
                  <div>
                    {t('description_text_three')}
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xl={6} lg={6} md={12} sm={12} xs={12} className="text-left">
                  <div>
                    {t('description_text_four')}
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xl={6} lg={6} md={12} sm={12} xs={12} className="text-left">
                  <div>
                    {t('description_text_five')}
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xl={6} lg={6} md={12} sm={12} xs={12} className="text-left">
                  <div>
                    {t('description_text_six')}
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xl={6} lg={6} md={12} sm={12} xs={12} className="text-left requirements-detail">
                  <h3>
                    {t('requirements').toUpperCase()}:
                  </h3>
                  <div>
                    <h4>
                      PC:
                    </h4>
                    <div>
                      <ul>
                        <li>
                          <div className="normal-text">{t('processor_data')}</div>
                        </li>
                        <li>
                          <div>{t('minimum_requirements')}</div>
                          <ul>
                            <li>
                              <span>{t('operation_system')}:</span> Windows 7, 8, 10 {t('or')} 11
                            </li>
                            <li>
                              <span>{t('processor')}:</span> Intel Core {/*<sup>TM &#174;</sup>*/} i5-7400, AMD {/*<sup>TM &#174;</sup>*/} {t('equivalent')}
                            </li>
                            <li>
                              <span>{t('working_memory')}:</span> 8 GB RAM
                            </li>
                            <li>
                              <span>{t('graphics')}:</span> NVIDIA GeForce {/*<sup>TM &#174;</sup>*/} GTX 1060, AMD Radeon {/*<sup>TM &#174;</sup>*/} {t('equivalent')}
                            </li>
                            <li>
                              <span>{t('vr_support')}:</span> Steam VR, OpenXR
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div>{t('recommended').toUpperCase()}</div>
                          <ul>
                            <li>
                              <span>{t('working_memory')}:</span> 16 GB RAM
                            </li>
                            <li>
                              <span>{t('graphics')}:</span> NVIDIA GeForce {/*<sup>TM &#174;</sup>*/} GTX 1070, AMD Radeon {/*<sup>TM &#174;</sup>*/} {t('equal_better')}
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div className="normal-text">{t('vr_compatible')}</div>
                        </li>
                      </ul>
                    </div>
                    <div className="bold-text">
                      {t('vr_support-devices')}:
                    </div>
                    <div>
                      <ul>
                        <li>
                          Meta Quest {/* 2 */} {/*<sup>TM &#174;</sup>*/}
                        </li>
                        <li>
                          PICO 4 {/*<sup>TM &#174;</sup>*/}
                        </li>
                        <li>
                          Sony Playstation 4 VR1 {/*<sup>TM &#174;</sup>*/}
                        </li>
                        <li>
                          Sony PlayStation 5 VR2 {/*<sup>TM &#174;</sup>*/}
                        </li>
                      </ul>
                    </div>
                    <div className="legal-text">
                      "PlayStation" is a registered trademark or trademark of Sony Interactive Entertainment Inc.
                    </div>
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            </ReactBootstrap.Container>
          </div>
        </div>
      </div>
    </>
  )
}

export default FivthTeaser;
