import React from 'react';
import {Link} from "react-router-dom";
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import BMWI_DE from "./../../assets/svg/BMWi_de.svg";
import BMWI_EN from "./../../assets/svg/BMWE_en.svg";
import NurogamesLogo from "./../../assets/Logos/logo_nurogames.png";

function Footer(){
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const { t, i18n } = useTranslation();

  const backToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 1000);
  }

  return (
    <>
      <div id="footer">
        <ReactBootstrap.Container>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
              {
                localStorage.getItem("language") == "" || localStorage.getItem("language") == null || localStorage.getItem("language") == "en" || localStorage.getItem("language") == "fr" || localStorage.getItem("language") == "it" || localStorage.getItem("language") == "es" ? (
                  <img src={BMWI_EN} alt="BMWi - English" className="bmwi-logo"/>
                ) : (
                  <img src={BMWI_DE} alt="BMWi - Deutsch" className="bmwi-logo"/>
                )
              }
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
              <a href="https://www.nurogames.com" alt="Nurogames GmbH - Website" target="_blank">
                <img src={NurogamesLogo} alt="Nurogames GmbH - Logo" className="nurogames-logo"/>
              </a>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            {
              window.location.pathname.length > 1 ? (
                <>
                  <ReactBootstrap.Col xs={12} sm={12} md={3} lg={3} xl={3} className="text-center">
                    <Link to="/" onClick={backToTop}>
                      {t('home')}
                    </Link>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={3} lg={3} xl={3} className="text-center">
                    <Link to="/imprint" onClick={backToTop}>
                      {t('imprint')}
                    </Link>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={3} lg={3} xl={3} className="text-center">
                    <Link to="/terms-of-service" onClick={backToTop}>
                      {t('terms-of-service')}
                    </Link>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={3} lg={3} xl={3} className="text-center">
                    <Link to="/privacy-policy" onClick={backToTop}>
                      {t('privacy-policy')}
                    </Link>
                  </ReactBootstrap.Col>
                </>
              ) : (
                <>
                  <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center">
                    <Link to="/imprint" onClick={backToTop}>
                      {t('imprint')}
                    </Link>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center">
                    <Link to="/terms-of-service" onClick={backToTop}>
                      {t('terms-of-service')}
                    </Link>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center">
                    <Link to="/privacy-policy" onClick={backToTop}>
                      {t('privacy-policy')}
                    </Link>
                  </ReactBootstrap.Col>
                </>
              )
            }
          </ReactBootstrap.Row>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
              <div>
                {t('made-with')} <i className="fa-solid fa-heart"></i> {t('by')} Nurogames {t('in')} {t('city')}
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </div>
    </>
  )
}

export default Footer;
