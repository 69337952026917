import React from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";

function English() {
  const {t, i18n} = useTranslation();

  const isMobile = useMediaQuery({query: '(max-width: 991px)'})
  const isBiggerMobile = useMediaQuery({query: '(min-width: 992px)'})

  return (
    <>
      <h2>Nurogames GmbH Privacy and Cookie Policy for End Users</h2>
      <p>
        The privacy and security of your personal data is of high importance
        to Nurogames GmbH ("Nurogames"). The following privacy and security
        policy outlines what personal data Nurogames collects and how it is
        used, when you use downloadable, online as well as mobile services
        and products offered by Nurogames or visit any live events organized
        by or in connection with Nurogames.
      </p>
      <h3>1. Contact Us</h3>
      <p>
        If you would like to contact us to exercise your rights or in case
        you have any questions or suggestions about this information, please
        contact us via: <strong>info@nurogames.com</strong>.
      </p>
      <p>
        The controller of the personal data processed under this policy is
        Nurogames GmbH, Schaafenstraße 25, 50676 Cologne, Germany.
      </p>
      <h3>2. Data collected by Nurogames</h3>
      <p>
        Depending on which products are used and how they are used, the data
        we collect from you can vary.
      </p>
      <h4>a) Data directly given to us by you</h4>
      <p>Data that is directly provided by you to us includes:</p>
      <ul>
        <li>Security questions and their answers</li>
        <li>
          Payment information such as your card number and the billing
          address
        </li>
        <li>
          Your Nurogames account information, which can include username,
          telephone number, email address, date of birth, password and
          country
        </li>
        <li>
          Details provided by you in surveys related to our products that
          you answer
        </li>
        <li>
          Data provided when requesting assistance from us such as your
          telephone number, email address, records of the problems you
          encountered and your name
        </li>
      </ul>
      <p>
        Taking part in online multiplayer games will lead to your gameplay,
        statistics and in-game profile being visible to all. Participating
        in competitive game modes may lead to us recording your controller
        inputs and gameplay. The resulting footage could be replayed to
        other players at any live event involving Nurogames including your
        game statistics and in-game profile. Please be aware that your
        privacy settings should be based on your preferences. The
        information that you disclose when resorting to the use of social
        applications (e.g. chat) could become publicly available. Moreover,
        it could be collected, copied, read or used in another form by other
        users.
      </p>
      <h4>b) Additional data collected during the use of our products</h4>
      <p>
        Other information is collected automatically by Nurogames when you
        use our products, which includes:
      </p>
      <ul>
        <li>Type of platform</li>
        <li>
          Referring and exit pages, which incorporates landing pages and
          pages viewed
        </li>
        <li>IP address</li>
        <li>
          Information on device events such as crash reports, referral and
          request URL's and details on your system activity (e.g. error
          reports while playing the game)
        </li>
        <li>
          Information on your browser such as browser language and type
        </li>
        <li>Hardware or device identifiers</li>
        <li>
          Information on which Nurogames products you use and your use of
          them
        </li>
        <li>
          Details about your software, device and hardware including
          Nurogames software and updates installed and used by you, and the
          presence of plug ins required, as well as your hardware settings
          and components
        </li>
      </ul>
      <p>
        In some cases we might collect and store data on your device
        locally, resorting to mechanisms such as application data caches,
        browser web storage (incl. HTML 5) and cookies.
      </p>
      <h4>c) Data provided to us by third parties</h4>
      <ol>
        <li>
          <strong>Wii / Wii U</strong>: By signing up to use our products on
          a Nintendo Wii or Wii U console, you agree that your Nintendo user
          account data may be provided to Nurogames by Nintendo in order for
          us to establish a Nurogames account for you. Some of our products
          will require you to use a Nurogames account if you play online. We
          receive information such as your email address, your country, date
          of birth, your Nintendo Network ID, language, friend list and your
          Mii information from Nintendo. However, we do not receive
          financial information such as your credit card number.
        </li>
        <li>
          <strong>Nintendo Switch™: </strong>By signing up to use our
          products on a Nintendo Switch™ console, you agree that your
          Nintendo user account data may be provided to Nurogames by
          Nintendo in order for us to establish a Nurogames account for you.
          To play our products online you will need a Nurogames account. We
          receive information such as your email address, your country, date
          of birth, your nickname, language and your friend list from
          Nintendo. However, we do not receive financial information such as
          your credit card number.
        </li>
        <li>
          <strong
          >PlayStation®3 &amp; PlayStation®4 Computer Entertainment
            Systems:</strong
          >
          By signing up to use our products on a PlayStation®3 or
          PlayStation®4 computer entertainment system, you agree that your
          Sony Entertainment Network account data will be provided to
          Nurogames in order for us to establish a Nurogames account for
          you. Some of our products will require you to use a Nurogames
          account if you play online. We receive information such as your
          email address, your country, your name, language, state or
          province, date of birth and Online ID from Sony. However, we do
          not receive financial information such as your credit card number.
        </li>
        <li>
          <strong>Xbox Live: </strong>By signing up to use our products on
          Microsoft's Xbox Live Service, you agree that your Microsoft user
          account data may be provided to Nurogames by Microsoft in order
          for us to establish a Nurogames account for you. Some of our
          products will require you to use a Nurogames account if you play
          online. We receive information such as your email address, your
          country, age, language, state or province and Gamertag from
          Microsoft. However, we do not receive financial information such
          as your credit card number.
        </li>
        <li>
          <strong>Mobile platforms:</strong> We receive data about you from
          the app stores and other mobile platform providers, if you
          purchase or use our applications on your tablet and/or mobile
          device. This data, for example, contains the information that you
          made a purchase, your device ID and/or your username, but it does
          not contain any financial or otherwise sensitive information.
          Also, some of our mobile platforms might provide us with data that
          you authorized them to provide. Further, our mobile products might
          ask for more personal information from you, such as your contact
          list or precise location and push notification tokens. This
          information will only be collected if you explicitly agree to our
          request.
        </li>
        <li>
          <strong>Facebook Connect: </strong>We also offer you the option of
          easier registration for our games and services through Facebook.
          You can use your existing Facebook user account for this purpose.
          By clicking the "Log in with Facebook" link, you can use this
          registration method via our online portal. To do this, you need to
          already have a Facebook account or have access to Facebook.<br />
          If you would like to register for one of our services using your
          Facebook account, the first step in the registration process will
          immediately redirect you to Facebook. Facebook will then ask you
          to log in or to register. Under no circumstances will we receive
          your personal access data (user name and password).<br />
          In a second step, you will connect your Facebook profile with the
          service for which you would like to register. At this point, you
          will be told what data from your Facebook profile will be
          transmitted to us. This information is usually your “public
          information” on Facebook and information which you have made
          available to the public or authorized for the application in
          question. Information of this type generally includes your name,
          profile picture and cover photo, your gender, your networks, your
          username (Facebook URL), and your user ID number (Facebook ID). We
          will also use the email address you have saved with Facebook in
          order to contact you outside of Facebook. You can see an overview
          of information in your profile that is available to the public via
          the General Account Settings menu of your Facebook profile (<a
          href="https://www.facebook.com/settings?tab=applications"
        >https://www.facebook.com/settings?tab=applications</a
        >).<br />
          The legal basis for data collection and storage is your consent,
          within the meaning of Art. 6 paragraph 1 sentence 1 letter a GDPR.
          If you would like to remove the connection between Facebook
          Connect and our service, please log in to Facebook and make the
          required changes to your profile. We will then no longer have the
          right to use information from your Facebook profile.
        </li>
        <li>
          <strong>Other third parties: </strong>We may use data from third
          parties, for example, to better identify your approximate location
          via your IP address in order to customise some of our offerings to
          your location, or for tax purposes, and for the purpose of
          preventing abuse and/or fraud. Further, third parties such as may
          provide us with data, in case you use these services in connection
          with our products. When other players decide to share their
          contact list with Nurogames, we may also receive data about you.
          In addition, we may also receive data from third parties to
          supplement the data we received from you. Data from these
          companies is used to optimise the advertising delivered to you in
          order to ensure that it is of relevance to you. An overview of
          these third parties can be found on <a href="https://privacyappendix.nurogames.com/"
          >privacyappendix.nurogames.com</a>.
        </li>
      </ol>
      <h4>d) Legal grounds for data processing</h4>
      <p>
        We resort to a number of legal grounds to process data about you, if
        you are a resident belonging to the European Union. For instance, we
        will process data concerning you, where you agreed to us doing so
        (Art. 6(1)(a) of EU General Data Protection Regulation [GDPR]),
        where we have a legal obligation to do so (Art. 6(1)(c) GDPR), where
        the processing is necessary to fulfill the requirements of a
        contract with you (Art. 6(1)(b) GDPR) and where we have a justified
        interest to do so (Art. 6(1)(f)). For instance, we resort to our
        justified interest in serving targeted marketing and on the
        necessity resulting from our contract to process data if you create
        a Nurogames account. If you want to receive additional information
        on the legal grounds on which we resort to in order to process your
        data for different purposes, please contact us.
      </p>
      <h3>3. Methods of collecting data used by Nurogames</h3>
      <p>
        We collect data from you when you provide the latter directly, e.g.
        when you fill out an online form. Additionally, we and third parties
        working with us, also apply some technologies outlined below to
        gather data in order to offer you an excellent service experience.
        The different methods how we collect data, directly or indirectly,
        are explained in the following.
      </p>
      <h4>a) Job Application</h4>
      <p>
        You can apply for a job with us through our website in the "career"
        section. To this end, we collect personal contact data from you,
        which specifically includes your name, your CV, your cover letter
        and other content provided by you.
      </p>
      <p>
        Your personal application data will only be collected, stored,
        processed and used for purposes in connection with your interest in
        current or future employment with us and the actual processing of
        your application. Your online application will only be processed and
        handled by the relevant contact people in our company. All employees
        entrusted with data processing are obliged to maintain the
        confidentiality of your data.
      </p>
      <p>
        The legal basis for this data collection is Section 26 BDSG 2018.
      </p>
      <h4>b) Cookies</h4>
      <p>
        Cookies can be described as small text files, which are stored on
        your internet browser. Nurogames applies cookies and similar
        technologies to better understand what web pages, ads or features
        you view and which games you play. We use this information to
        improve your user experience, e.g. by keeping track of your
        purchases and measure the efficiency of our advertising, thereby
        trying to prevent that you are confronted with the same ads
        repeatedly. You can set your browser instructions to notify you in
        case cookies are sent, disable cookies or introduce limitations to
        the type of cookies that you permit. In order to gain a better
        understanding of how to manage your cookie preferences, we recommend
        you to refer to guidance by the web browser you use. Furthermore, we
        resort to other technologies for similar purposes as a cookie. We
        apply, amongst other tools, tracking pixels or clear GIFs to collect
        data concerning your use of Nurogames’s products and your response
        to our emails. Moreover, we resort to Internet log files to gain a
        better understanding of the traffic on our products, identify and
        prevent fraud, enforce our User Agreement and solve technical
        problems. Further, we also use device fingerprints, "flash cookies",
        Silverlight Application Storage and HTML 5 cookies. By changing your
        preferences in the Macromedia Website Privacy Settings Panel at <a
          href="https://www.macromedia.com/support/documentation/en/flashplayer/help/"
        >www.macromedia.com/support/documentation/en/flashplayer/help/</a
        >, you can delete flash cookies or stop them from being placed. As
        of now, we do not respond to Do Not Track (DNT) signals but we might
        do so if a DNT standard is established. In the meantime, you can
        disable certain types of tracking such as tailored advertising by
        adjusting your cookie settings.
      </p>
      <h4>c) Use of Analytical Technologies</h4>
      <p>
        Nurogames uses its own game servers and internal and third party
        analytical tools to collect data about how you use your computer or
        device, our product and about the health of our products. For
        example, Nurogames may store and collect information from your
        device or computer when you use its products. This data consists of
        technical and related information regarding your computer or device
        and the respective operating system (e.g. IP address and device ID),
        gameplay and usage statistics, data on your feature usage, system
        interactions and peripheral hardware. Playing a game offline will
        lead to the data being stored on your device, whereat it will be
        transmitted to Nurogames once your device has an Internet connection
        again. If you take part in online services, Nurogames may also
        collect, store, use, publicly display and transmit your statistical
        data and/or persona concerning your game play (e.g. accomplishments,
        scores and rankings), or identify content created and shared by you
        with other players. Further, third party analytical tools used by
        our products may combine information collected in the scope of
        Nurogames’s services with data they have independently collected
        over time from different sources. These companies mostly collect and
        use data in accordance to their own privacy and security policies. A
        list of third party analytical companies operating in our products
        and how to evade the extent applicable can be found at <a href="https://privacyappendix.nurogames.com/"
        >privacyappendix.nurogames.com</a
        >.
      </p>
      <h4>d) Technologies used for Ads</h4>
      <p>
        Some of our products resort to ad serving technologies which employ
        clear GIFs, tracking pixels, cookies, web beacons and other similar
        technologies such as identifiers to propose offers to you. These
        technologies can connect or synchronize behavior across devices,
        different websites and mobile apps in some cases. Further, the ads
        can also be customized to meet your interests, e.g. you may be
        offered a game we think you would like or if you have shown interest
        in a certain game, you may be shown advertisements for that game or
        similar products on Nurogames or third party sites. Through other
        in-game advertising technologies, advertising can temporarily be
        uploaded to your web browser, game or mobile device and later
        substituted while you are online. These technologies collect and use
        data in order for us to show suitable ads to you and track results
        as well as measure effectiveness. Apart from identifiers, there is
        information including:
      </p>
      <ul>
        <li>domain type, size of the advertisement and IP address</li>
        <li>advertisement served and the location of the ad</li>
        <li>advertisement response</li>
        <li>length of time an ad was visible</li>
      </ul>
      <p>
        Third party advertising companies may combine data they have
        independently collected across different websites over time with the
        information collected while delivering an ad to you via our
        products. These companies mostly collect and use data resorting to
        their own privacy and security policies. A list of ad companies
        operating their own networks on our online and/or mobile products
        and/or services and on our sites and how to evade them to the extent
        applicable can be found at <a href="https://privacyappendix.nurogames.com/"
        >privacyappendix.nurogames.com</a
        >. In order to gain more insight concerning some of these ad
        networks’ methods, or to evade third party targeted advertising, you
        can visit <a href="https://www.networkadvertising.org/"
        >www.networkadvertising.org</a
        >, <a href="https://www.aboutads.info/choices/"
        >www.aboutads.info/choices</a
        >, <a href="http://youradchoices.ca/">youradchoices.ca</a>, or <a href="https://www.youronlinechoices.eu/"
        >www.youronlinechoices.eu</a
        >. Evading these ads does not mean that you will not see any ads; it
        just means that they will not be tailored to your preferences
        anymore.
      </p>
      <h4>e) Fraud and Anti-Cheat Prevention Technologies</h4>
      <p>
        Nurogames aims to provide a fair and safe gaming experience to all
        of our players. In order to prevent fraud and for security and
        authentication purposes, we or third parties may apply cookies and
        similar technologies or collect information regarding your device or
        machine to create a machine "fingerprint" or "hash" of your machine
        components, when you log in to one of our products, make purchases
        or create a Nurogames account. Further, we also may apply anti-cheat
        technology in our products such as automated abuse and anti-fraud
        algorithms. In case you believe that your access to our products
        and/or services was blocked without justification, please contact
        Nurogames at <strong>info@nurogames.com</strong>.
      </p>
      <h3>4. Storage Location of Your Information</h3>
      <p>
        Personal data we collect may be stored and processed for the
        purposes outlined in this policy in Germany or any other country in
        which Nurogames, its subsidiaries or third party agents operate.
        Third parties are certified under the Privacy Shield Agreement and
        thus guarantee compliance with European data protection legislation
        (<a href="https://www.privacyshield.gov/"
      >https://www.privacyshield.gov</a
      >). By using our products, you acknowledge that your personal data
        may be transmitted to recipients in the United States and other
        countries that may not have the same level of privacy protection in
        place as it is the case for the laws in your country of residence or
        citizenship.
      </p>
      <h3>5. Usage of your data</h3>
      <p>
        We use your non-personal and personal data, both individually and
        combined together in the following ways:
      </p>
      <p>
        To enhance and personalize your gaming experience and operate our
        business including to:
      </p>
      <ul>
        <li>
          Provide you with personalized recommendations for Nurogames
          products and/or features
        </li>
        <li>
          Help you find your friends and tell your friends about a Nurogames
          product
        </li>
        <li>Measure the health of our services</li>
        <li>Provide software updates</li>
        <li>
          Help keep our services fair and safe, investigate and help curb
          fraud and illegal behavior, to enforce our agreements and
          policies, resolve disputes and comply with the law
        </li>
        <li>
          Authenticate or activate your games and save stats and game
          progress
        </li>
        <li>Facilitate your gameplay on multiple devices when available</li>
        <li>Facilitate sharing on social networks</li>
        <li>Populate leaderboards online and enable online matchmaking</li>
        <li>Develop, operate and improve our games and services</li>
        <li>
          Provide replays to other players and you using spectator modes
        </li>
        <li>Identify, fix and troubleshoot service errors and bugs</li>
        <li>Deliver dynamic content and adhere to your preferences</li>
        <li>Set up loyalty programs</li>
        <li>Set up and maintain accounts you register for</li>
        <li>Provide game experiences that may be to your liking</li>
        <li>Serve and measure the effectiveness of advertising</li>
      </ul>
      <p>To provide you support, including to:</p>
      <ul>
        <li>Survey your opinions through surveys or questionnaires</li>
        <li>
          Manage and send you important information and confirmations about
          your account, purchases, products, warranties and subscriptions
        </li>
        <li>
          Respond to your specific questions or requests and communicate
          with you
        </li>
        <li>
          Help identify and troubleshoot problems related to your account or
          games
        </li>
      </ul>
      <p>To personalize our communications with you, including to:</p>
      <ul>
        <li>
          Customize advertising for you and deliver targeted marketing,
          promotional offers and service updates
        </li>
        <li>Make recommendations to you</li>
        <li>
          Provide information and/or offers related to games you might like
        </li>
      </ul>
      <p>
        We keep the data we collect for as long as necessary to provide our
        services and products and we keep the information beyond this period
        if necessary for operational, legal or other legitimate reasons.
      </p>
      <h3>6. Protection of Personal Information</h3>
      <p>
        The security of players' information is of great importance to
        Nurogames and we resort to different actions to protect their data.
        However, no security measure is completely perfect. Consequently, we
        cannot vouch for the security of your information and thus do not
        assume any responsibility for unauthorized access or use to your
        information under our control.
      </p>
      <h3>7. Data Shared with Third Parties</h3>
      <p>
        We do not share any personal data that directly identifies you (e.g.
        postal address, e-mail, your name) with third parties for their own
        us without your consent. Exceptions are if we determine that
        disclosure is reasonably necessary to enforce our rights, operations
        or property or there is a requirement by law or to protect our
        players or third parties. We may share anonymous or aggregated
        information or other information, which cannot be used to directly
        identify you, with third parties, for example, your game stats on
        leaderboards, your persona or to show trends about the usage of our
        products.
      </p>
      <p>
        To collect and process personal information on our behalf for
        different reasons, such as facilitating credit card processing,
        sending emails or conducting market surveys, we employ third parties
        in and outside of your country of residence (including outside of
        the European Union). In case third party agents or service providers
        collect or receive personal data, we require them to use the
        information exclusively on our behalf and for purposes consistent
        with this policy. Further, we do not knowingly disclose personal
        data concerning children under 18 years of age to independent third
        parties, even with a consent. Nevertheless, in case of a divestiture
        merger, sale, bankruptcy or reorganisation, we might transmit all
        information we collect to the respective third party, while
        obtaining your consent to do so, if required by law. Moreover, we do
        not control information sent from your browser to third parties such
        as analytics companies and advertising networks that receive data in
        the course of your normal Internet usage. When third party social
        tools or technologies are part of our products, the responsible
        third parties may collect data when you use our products. For
        instance, the Facebook "Like" button.
      </p>
      <h3>8. Services of Third Parties</h3>
      <p>
        Our products and websites may contain services of third parties or
        advertising such as links to third party websites or the Facebook
        Like button. You will leave the Nurogames website and go to the site
        you selected, if you click on one of those links, including
        advertisement. In case you use a third party service or go to a
        third party website, you should be aware of that service's or site's
        privacy policy.
      </p>
      <h3>9. Privacy of Children</h3>
      <p>
        It is of utmost importance to Nurogames to protect children’s
        privacy online. By children we mean individuals under the age of 13
        or the minimum age in the respective territory if that age is older
        than 13. Many Nurogames mobile or online services and products are
        designed for general audiences and do not knowingly collect any
        personal data from children. When users identify themselves as being
        children, we will: (1) collect certain information for limited
        purposes only, (2) block or restrict the child from accessing
        relevant services such as chat functionality, (3) not provide a path
        for them to input personal information; and/or (4) obtain consent
        from their parents for the use of their children's personal data,
        all according to applicable law. Using our mobile applications your
        child will be able to receive in-app notifications, which do not
        collect any personal data.
      </p>
      <p>
        Please be aware that if you give consent for your child to use
        Nurogames's mobile or online services and products, this may include
        general audience communication services such as instant messaging,
        online groups and email, where your child will be able to
        communicate with and disclose personal information to other users of
        all ages. If as a parent you wish to revoke a previously provided
        consent, review information collected from your child or have this
        information deleted, contact the appropriate Privacy Policy
        Administrator at Nurogames GmbH, Schaafenstraße 25, 50676 Cologne,
        Germany or via <strong>info@nurogames.com</strong>.
      </p>
      <p>
        Collected information is processed and stored in a manner consistent
        with this Privacy and Cookie Policy. We will notify parents by
        in-game notice or by email to receive verifiable consent from them,
        if we make changes to how we collect, use or share their child's
        personal data. Nevertheless, we encourage parents to talk with their
        children about disclosing personal information online and
        communicating with strangers.
      </p>
      <p>
        Under no circumstances do we condition a child's participation in
        activities such as contests on its disclosure of more personal data
        than is necessary to take part in the activities. We may not grant
        children the permission to participate at all on certain sites
        regardless of consent.
      </p>
      <h3>10. User Choices and Control</h3>
      <p>
        We provide you with meaningful choices concerning important uses and
        collection of information. For instance, you can decide to opt in or
        out of Nurogames marketing emails, choose whether your information
        is shared with selected partners of Nurogames and update your
        preferences and account information. If you choose to opt out any
        time after you have given your consent, contact the appropriate
        Privacy Policy Administrator at Nurogames GmbH, Schaafenstraße 25,
        50676 Cologne, Germany or via <strong>info@nurogames.com</strong>.
      </p>
      <p>
        Most of your preferences can be reviewed and modified using your
        Nurogames account settings. In those cases where we need a consent
        to collect and use data concerning you, this consent can be
        withdrawn any time by you. You can delete personal data associated
        with your Nurogames account or deactivate your Nurogames account. By
        doing so, you will lose access to Nurogames services or games
        associated with your account. Be aware that Nurogames may keep data
        needed to enforce our user agreements, comply with legal and
        technical requirements, resolve disputes, protect our legal rights
        and comply with constraints related to security, operation and
        integrity of our products. In any other case, we will keep your
        personal data for as long as reasonably necessary to create and
        improve our products, run our business, provide you services and
        comply with the law.
      </p>
      <p>
        You can access the personal data we hold about you. Please contact <a href="https://help.nurogames.com/">help.nurogames.com</a> to
        request access. In order to verify your identity, we may ask for
        certain personal information before we process any request. Under
        local law you may have additional rights such as the right to
        restrict or object to processing of information and the right to
        request portability or erasure of personal data. You can exercise
        these rights by contacting <a href="https://help.nurogames.com/">help.nurogames.com</a>, where
        applicable.
      </p>
      <p>
        If you want to block certain online tracking such as cookies, you
        may modify your browser settings.
      </p>
      <h3>11. Changes to our Policy</h3>
      <p>
        Please review this policy frequently since we may change this policy
        from time to time. In case applicable data protection laws require
        us to notify you in an enhanced matter or request your consent to
        any such changes, we will do so. To see when this policy was last
        updated, please refer to the "last updated" date displayed at the
        top of this policy.
      </p>
      <h3>12. Contact a Data Protection Authority</h3>
      <p>
        Please contact us, if you are concerned with the way we collect and
        use data. If you prefer, you also are entitled to contact your local
        Data Protection Authority . The contact details for these
        Authorities in the EU can be found at <a
          href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
        >ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
      </p>
    </>
  );
}

export default English;
