import React, {useEffect} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import { ScrollRotate } from 'react-scroll-rotate';

import StoneRuneCircle from './../../assets/Png/GraphicStoneCircle.png';
import borderGame from "../../assets/Png/GraphicFrame.png";
import SteamLogo from "../../assets/Png/GraphicSteamLogo.png";
import ViveportLogo from "../../assets/Png/GraphicViveportLogo.png";
import PicoLogo from "../../assets/Png/GraphicPicoLogo.png";
import PS5Logo from "../../assets/Png/GraphicLogoPS5.png";
import PS4Logo from "../../assets/Png/GraphicLogoPs4.png";
import MetaQuestLogo from "../../assets/Png/GraphicLogoMetaQuest.png";
// import MetaQuestLogo from "../../assets/Png/GraphicLogoMetaQuestOriginal.png";
import EpicLogo from "../../assets/Png/GraphicEpicGamesLogo.png";

function SecondTeaser(){
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const isBiggerMobile = useMediaQuery({ query: '(min-width: 992px)' })

  const { t, i18n } = useTranslation();

  const goToWebsite = (url) => {
    window.open(url, '_blank');
  };

  /*useEffect(() => {
    let platformElement = document.querySelectorAll('.platform-element');

    platformElement[0].addEventListener('click', function(){
      window.open("https://store.steampowered.com/app/2387750/Rune_Bender_VR", '_blank');
    });
  }, []);*/

  return (
    <>
      <div className="teaser-platform" id="platforms">
        {
          isBiggerMobile ? (
            <>
              {/*<div className="corner-stone-rune">
                <ScrollRotate>
                  <img src={StoneRuneCircle} alt="Stone Runes"/>
                </ScrollRotate>
              </div>*/}
              {/*<div className="frame-game content-platform">
                <img src={borderGame} alt="Border"/>
              </div>*/}
            </>
          ) : (
            <></>
          )
        }
        <div className="platforms">
          <ReactBootstrap.Container>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} className="text-center">
                <h1>
                  {t('platforms')}
                </h1>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xl={4} lg={4} md={6} sm={12} xs={12} className="text-center platform-element" onClick={() => goToWebsite("https://store.steampowered.com/app/2387750/Rune_Bender_VR")}>
                <img src={SteamLogo} alt="Steam"/>
                <div>
                  {t('steam-date')}
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xl={4} lg={4} md={6} sm={12} xs={12} className="text-center platform-element">
                <img src={MetaQuestLogo} alt="Meta Quest"/>
                <div>
                  (2025)
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xl={4} lg={4} md={6} sm={12} xs={12} className="text-center platform-element">
                <img src={PicoLogo} alt="Pico"/>
                <div>
                  (2025)
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xl={4} lg={4} md={6} sm={12} xs={12} className="text-center platform-element">
                <img src={PS4Logo} alt="Playstation 4"/>
                <div>
                  (2025)
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xl={4} lg={4} md={6} sm={12} xs={12} className="text-center platform-element">
                <img src={PS5Logo} alt="Playstation 5"/>
                <div>
                  (2025)
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xl={4} lg={4} md={6} sm={12} xs={12} className="text-center platform-element">
                <img src={ViveportLogo} alt="Viveport"/>
                <div>
                  (2024)
                </div>
              </ReactBootstrap.Col>
              {
                isBiggerMobile ? (
                  <ReactBootstrap.Col xl={4} lg={4} md={6} sm={12} xs={12} className="platform-element platform-element-hide"></ReactBootstrap.Col>
                ) : (
                  <></>
                )
              }
              {/*<ReactBootstrap.Col xl={4} lg={4} md={6} sm={12} xs={12} className="text-center platform-element">
                <img src={EpicLogo} alt="Epic"/>
              </ReactBootstrap.Col>*/}
              {
                isBiggerMobile ? (
                  <ReactBootstrap.Col xl={4} lg={4} md={6} sm={12} xs={12} className="platform-element platform-element-hide"></ReactBootstrap.Col>
                ) : (
                  <></>
                )
              }
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </div>
      </div>
    </>
  )
};

export default SecondTeaser;
