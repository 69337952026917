import React, {useEffect} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import { ScrollRotate } from 'react-scroll-rotate';

import StoneRuneCircle from './../../assets/Png/GraphicStoneCircle.png';
import borderGame from "../../assets/Png/GraphicFrame.png";

import Trailer from "../../assets/videos/trailer.mp4";
import TrailerPoster from "../../assets/videos/trailer-poster.png";

function ThirdTeaser(){
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const isBiggerMobile = useMediaQuery({ query: '(min-width: 992px)' })

  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="teaser-trailer" id="trailer">
        {
          isBiggerMobile ? (
            <>
              {/*<div className="corner-stone-rune-trailer">
                <ScrollRotate>
                  <img src={StoneRuneCircle} alt="Stone Runes"/>
                </ScrollRotate>
              </div>*/}
              {/*<div className="frame-game content-platform">
                <img src={borderGame} alt="Border"/>
              </div>*/}
            </>
          ) : (
            <></>
          )
        }
        <div className="trailer">
          <ReactBootstrap.Container>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} className="text-center">
                <h1>
                  {t('trailer')}
                </h1>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} className="text-center">
                <video className="dynamic-video" controls poster={TrailerPoster}>
                  <source src={Trailer} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </div>
      </div>
    </>
  )
}

export default ThirdTeaser;
