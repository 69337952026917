import React from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";

function German() {
  const {t, i18n} = useTranslation();

  const isMobile = useMediaQuery({query: '(max-width: 991px)'})
  const isBiggerMobile = useMediaQuery({query: '(min-width: 992px)'})

  return (
    <>
      <h2>
        <b>Nurogames GmbH </b
        ><b>Datenschutz- und Cookie-Richtlinie für den Endverbraucher</b>
      </h2>
      <p>
        Die Nurogames GmbH ("Nurogames") verpflichtet sich, die Privatsphäre
        und Sicherheit Ihrer personenbezogenen Daten zu schützen. Diese
        Richtlinie beschreibt, welche Daten Nurogames erhebt, wenn Sie die
        Mobile-, Online- und Download-Produkte und Dienste von Nurogames
        nutzen und/oder an Live-Events von oder im Zusammenhang mit
        Nurogames teilnehmen ("Produkte"). Sie gilt für alle Endverbraucher,
        einschließlich zahlender und nichtzahlender Nutzer.<b></b>
      </p>
      <h3><b>1. Kontakt</b></h3>
      <p>
        Bei Beschwerden oder Fragen zu dieser Richtlinie wenden Sie sich
        bitte an unseren Datenschutzbeauftragten unter <b>info@nurogames.com</b>.
      </p>
      <p>
        Verantwortliche Stelle für die gemäß dieser Richtlinie verarbeiteten
        personenbezogenen Daten ist Nurogames GmbH, Schaafenstraße 25, 50676
        Cologne, Germany.
      </p>
      <h3><b>2. Die Daten, die von uns gesammelt werden</b></h3>
      <p>
        Die Datenerhebung unsererseits ist abhängig von den Produkten die
        Sie Nutzen und der Art und Weise wie Sie diese nutzen. Weitere
        Informationen finden Sie im folgenden.
      </p>
      <h4><b>a) Von Ihnen direkt bereitgestellte Daten</b></h4>
      <p>
        Von Ihnen bereitgestellte Daten werden von uns erhoben,
        einschließlich:
      </p>
      <ul>
        <li>
          Ihre Nurogames-Konto-Informationen, einschließlich E-Mail-Adresse,
          Benutzername, Land, Geburtstag, Telefonnummer und Passwort;
        </li>
        <li>Sicherheitsfragen und Antworten;</li>
        <li>
          Rechnungsdaten, wie Ihren Namen, Ihre Zahlungskartennummer und
          Ihre Kontoinformationen;
        </li>
        <li>
          Daten, die Sie bereitstellen, wenn Sie unseren Kundendienst
          kontaktieren, einschließlich Ihres Namens, Ihrer Telefonnummer
          sowie Aufzeichnungen über die aufgetretenen Probleme;
        </li>
        <li>
          Angaben, die Sie im Zusammenhang mit Umfragen zu unseren Produkten
          bereitstellen.
        </li>
      </ul>
      <p>
        Sollten Sie an Online-Multiplayer-Spielen teilnehmen, sind Ihre
        Ingame-Profil-Informationen, Ihr Gameplay und Ihre Statistiken für
        alle sichtbar. In wettkampf-basierten Spielmodi können wir Ihr
        Gameplay und Ihre Controller-Eingaben aufzeichnen und diese zusammen
        mit Ihren Ingame-Profilinformationen und Ihren Spielstatistiken
        anderen Spielern im Spiel und bei Live-Events von Nurogames oder
        seinen Partnern vorführen.
      </p>
      <p>
        Nehmen Sie an Social Features (zum Beispiel einem Chat) teil, können
        die von Ihnen mitgeteilten Daten öffentlich zugänglich werden. Sie
        können auch von anderen Nutzern gelesen, kopiert, erhoben oder
        genutzt werden. Bitte stellen Sie sicher, dass die
        Privatsphäre-Einstellungen Ihren Präferenzen entsprechen.
      </p>
      <h4>
        <b>b) Zusätzlich erhobene Daten, wenn Sie unsere Produkte nutzen</b>
      </h4>
      <p>
        Bei der Nutzung unserer Produkte Ihrerseits erheben wir unter
        anderem folgende Daten automatisch:
      </p>
      <ul>
        <li>IP-Adresse;</li>
        <li>Plattformtyp;</li>
        <li>
          Browser-Informationen, z.B. Ihres Browsertyps und der von Ihnen
          gewählten Sprache;
        </li>
        <li>
          Verweis- und Exitseiten, einschließlich Einstiegsseiten und
          besuchte Seiten;
        </li>
        <li>
          Daten über Ihr Gerät sowie Ihre Hard- und Software, beispielsweise
          Ihre Hardware-Einstellungen und -Komponenten, Nurogames-Software
          und Updates, die Sie installiert haben oder nutzen, sowie das
          Vorhandensein benötigter Plug-ins;
        </li>
        <li>Mobile- und andere Hardware- oder Gerätekennungen;</li>
        <li>
          gerätebezogene Event-Auskünfte, wie Absturzberichte, Anfrage- und
          Verweisseiten, sowie Details zu Systemaktivitäten (z. B. ob ein
          Fehler beim Spielen unserer Spiele aufgetreten ist oder Ihre
          Internetverbindung unterbrochen wurde); und
        </li>
        <li>
          Detaillierte Informationen über die Nurogames-Produkte, die Sie
          nutzen sowie die Art der Nutzung.
        </li>
      </ul>
      <p>
        Zusätzlich ist es uns möglich, Daten lokal auf Ihrem Gerät unter der
        Nutzung von Technologien wie Anwendungs-Daten-Cache, Cookies und
        Browser-Webspeichern (einschließlich HTML 5) zu erheben und zu
        speichern.
      </p>
      <h4>
        <b>c)</b
        ><b>
        Daten, welche durch Dritte für Nurogames bereitgestellt werden</b
      >
      </h4>
      <ol>
        <li>
          <b>Xbox Live</b>. Sollten Sie sich für das Spielen von
          Nurogames-Spielen über Microsofts Xbox Live-Service registrieren,
          werden von Microsoft Informationen über Ihr Xbox
          Live-Benutzerkonto an Nurogames weitergeleitet, damit es uns
          möglich ist, ein Nurogames-Konto für Sie einzurichten. An
          Nurogames übermittelte Daten umfassen Ihre E-Mail-Adresse, Ihr
          Gamertag, Bundesland oder Provinz, Sprache, Alter und Land.
          Kreditkartennummern oder andere Bankkontoinformationen werden
          nicht übermittelt. Damit Sie die Möglichkeit haben, einige Spiele
          von Nurogames online spielen zu können, ist ein Nurogames-Konto
          notwendig. Für die Erstellung des Nurogames-Kontos ist die
          Übermittlung der oben genannten Daten erforderlich.
        </li>
        <li>
          <b
          >PlayStation®3 und PlayStation®4
            Computer-Entertainment-Systeme</b
          >. Sollten Sie sich registrieren, um Nurogames-Spiele auf einem
          PlayStation Computer-Entertainment-Systeme zu spielen, werden
          Informationen über ihr Sony Entertainment Network-Konto an
          Nurogames weitergeleitet, damit es uns möglich ist, ein
          Nurogames-Konto für Sie einzurichten. An Nurogames übermittelte
          Daten umfassen Ihren Namen, Ihre Online-ID, Ihre E-Mail-Adresse,
          Ihr Geburtsdatum, Ihre Sprache und Ihr Land. Kreditkartennummern
          oder andere für finanzielle Transaktionen erforderliche
          Konto-Informationen sind davon ausgenommen. Damit Sie die
          Möglichkeit haben, einige Spiele von Nurogames online spielen zu
          können, ist ein Nurogames-Konto notwendig. Für die Erstellung des
          Nurogames-Kontos ist die Übermittlung der oben genannten Daten
          erforderlich.
        </li>
        <li>
          <b>Nintendo Switch</b>. Sollten Sie sich für das Spielen von
          Nurogames-Spielen über &nbsp;die Nintendo Switch Konsole
          registrieren, werden Informationen über Ihren Nintendo-Account an
          Nurogames weitergeleitet, damit es uns möglich ist, ein
          Nurogames-Konto für Sie einzurichten. Damit Sie die Möglichkeit
          haben, einige Spiele von Nurogames online spielen zu können, ist
          ein Nurogames-Konto notwendig. Für die Erstellung des
          Nurogames-Kontos ist die Übermittlung der folgenden Daten
          erforderlich. Von Nintendo an Nurogames übermittelte Daten
          umfassen Ihren “Spitznamen”, Ihre Freundesliste, Ihre
          E-Mail-Adresse, Sprache, Land und Ihr Geburtsdatum. Ihre
          Kreditkartennummern oder andere Bankkontoinformationen werden
          nicht übermittelt.
        </li>
        <li>
          <b>Wii / Wii U</b>. Sollten Sie sich für das Spielen von
          Nurogames-Spielen über Nintendos Wii U Konsole registrieren,
          werden von Nintendo Informationen über Ihr Nintendo Konto an
          Nurogames weitergeleitet, damit es uns möglich ist, ein
          Nurogames-Konto für Sie einzurichten. Damit Sie die Möglichkeit
          haben, einige Spiele von Nurogames online spielen zu können, ist
          ein Nurogames-Konto notwendig. Für die Erstellung des
          Nurogames-Kontos ist die Übermittlung der folgenden Daten
          erforderlich. An Nurogames übermittelte Daten umfassen Ihre
          sogenannten “Mii”-Informationen, Ihre Nintendo-Network-ID, Ihre
          E-Mail-Adresse, Ihre Freundesliste, Sprache, Land und Ihr
          Geburtsdatum. Ihre Kreditkartennummern oder andere
          Bankkontoinformationen werden nicht übermittelt.
        </li>
        <li>
          <b>Mobile Plattformen</b>. Sollten Sie unsere Spiele auf Ihrem
          Mobilgerät und/oder Tablet erwerben oder spielen, erhalten wir von
          den Mobile-Plattformbetreibern und den App Stores Daten über Sie.
          Zu diesen Daten gehören Ihr Nutzername und/oder Ihre Geräte-ID
          sowie der Umstand, dass Sie einen Kauf getätigt haben. Sensible
          Daten oder Bankdaten gehören jedoch nicht dazu. Daten, die Sie zur
          Übersendung freigegeben haben, können von einigen unserer mobilen
          Plattformen an uns gesendet werden Unsere Mobile-Produkte bitten
          Sie möglicherweise um weitere personenbezogene Daten, wie Ihren
          genauen Standort, Push-Benachrichtigungstoken, &nbsp;oder Ihre
          Kontaktliste; diese Daten erheben wir nur nach Ihrer
          ausdrücklichen Einwilligung.
        </li>
        <li>
          <b>Facebook Connect. </b>Wir bieten Ihnen auch die Möglichkeit
          einer einfacheren Registrierung zu unseren Spielen und Services
          mit Facebook an. Hierfür können Sie Ihr bestehendes Nutzer-Konto
          bei Facebook nutzen. Durch den Button "Login mit Facebook" können
          Sie diese Registrierungsmöglichkeit auf unserem Portal nutzen.
          Hierfür ist erforderlich, dass Sie bei Facebook bereits
          registriert sind oder einen Facebook-Zugang erstellen.<br />
          Wenn Sie sich über Ihr Facebook-Konto bei uns anmelden wollen,
          werden Sie in einem ersten Schritt unmittelbar zu Facebook
          weitergeleitet. Dort bittet Facebook Sie sich anzumelden oder zu
          registrieren. In keinem Fall erfahren wir dabei Ihre persönlichen
          Zugangsdaten (Benutzername und Passwort).<br />
          In einem zweiten Schritt verbinden Sie Ihr Profil bei Facebook mit
          dem jeweiligen Dienst, für den Sie sich registrieren wollen. Sie
          erfahren dann auch, welche Daten aus Ihrem Facebook-Profil an uns
          übertragen werden. Hierzu gehören im Regelfall Ihre "öffentlichen
          Information" bei Facebook und solche, die Sie öffentlich
          zugänglich machen oder für die jeweilige Anwendung freigeben.
          Hierzu zählen in der Regel Ihr Name, Ihr Profil- und Titelbild,
          Ihr Geschlecht, Ihre Netzwerke, Ihr Nutzername (Facebook-URL) und
          Ihre Nutzerkennnummer (Facebook-ID). Um mit Ihnen unabhängig von
          Facebook in Kontakt treten zu können, verwenden wir auch Ihre bei
          Facebook hinterlegte E-Mail-Adresse. Einen Überblick, welche
          Informationen in Ihrem Profil öffentlich sind, erhalten Sie im
          Einstellungsmenu Ihres Facebook-Profils (<a
          href="https://www.facebook.com/settings?tab=applications"
        >https://www.facebook.com/settings?tab=applications</a
        >).<br />
          Rechtsgrundlage für die Datenerhebung und Speicherung ist Ihre
          Einwilligung im Sinne von Art. 6 Abs. 1 Satz. 1 Buchstabe a DSGVO.
          Möchten Sie die Verbindung von Facebook Connect zu unserem Dienst
          aufheben, melden Sie sich bitte bei Facebook an und stellen dort
          die erforderlichen Änderungen in Ihrem Profil ein. Wir haben dann
          keine Berechtigung mehr, Angaben aus Ihrem Facebook-Profil zu
          nutzen.
        </li>
        <li>
          <b>Weitere Drittanbieter</b>.<b> </b>Sollten Sie Dienste von
          Drittanbietern im Zusammenhang mit unseren Produkten nutzen, so
          können wir durchaus auch Daten von diesen Drittanbietern erhalten.
          Ferner erhalten wir Daten über Sie, wenn andere Spieler ihre
          Kontaktliste mit uns teilen.<br />
          Darüber hinaus erhalten wir Daten von Drittanbietern, um die von
          Ihnen bereitgestellten Daten zu ergänzen. Wir nutzen die Daten
          dieser Unternehmen primär für die Bereitstellung von für Sie
          relevante Werbeanzeigen. Eine Liste solcher Drittanbieter finden
          Sie auf <a href="https://privacyappendix.nurogames.com/"
          >privacyappendix.nurogames.com</a
          >. Außerdem können wir Daten von Drittanbietern verwenden, welche
          uns helfen, Ihren ungefähren Standort anhand Ihrer IP-Adresse zu
          erkennen, zum Beispiel zur Anpassung bestimmter Dienste an Ihren
          Standort, zur Verhinderung von Missbrauchs- und/oder
          Betrugsversuchen sowie zu steuerlichen Zwecken.
        </li>
      </ol>
      <h4><b>d) Rechtsgrundlage für die Verarbeitung</b></h4>
      <p>
        Die Verarbeitung Ihrer Daten beruht auf unterschiedlichen
        Rechtsgrundlagen, wenn Sie ein Bürger der Europäischen Union sind.
        Beispielsweise verarbeiten wir Daten über Sie, wenn ein legitimes
        Interesse dazu besteht (Art. 6(1)(f) DSGVO), wenn wir Ihr
        Einverständnis dazu besitzen (Art. 6(1)(a) DSGVO), wenn wir
        rechtlich dazu verpflichtet sind (Art. 6(1)(c) DSGVO) oder wenn die
        Erfüllung eines Vertrages mit Ihnen eine Verarbeitung Ihrer Daten
        erfordert (Art. 6(1)(b) DSGVO). Die Bereitstellung eines
        zielgerichteten Marketings beruht beispielsweise auf unseren
        rechtmäßigen Interessen, und die Verarbeitung Ihrer Daten, wenn sie
        ein Nurogames-Konto erstellen, auf der vertraglichen Notwendigkeit.
        Sollten Sie mehr über die unterschiedlichen rechtlichen Grundlagen
        erfahren wollen, auf die wir uns bei der Verarbeitung ihrer Daten
        stützen, bitten wir Sie, uns zu kontaktieren.
      </p>
      <h3><b>3. Die Art und Weise der Datenerhebung</b></h3>
      <p>
        Einiger der Daten, die Sie uns direkt zur Verfügung stellen, werden
        von uns erhoben. Dies kann beispielsweise über ein Online-Formular
        oder ein Gespräch mit unserem Kundenservice stattfinden. Zusätzlich
        setzen wir und Drittanbieter (im Folgenden beschriebene)
        Technologien ein, um Daten zu erheben, welche für ein hervorragendes
        Spielerlebnis unabdingbar sind. Die verschiedenen Methoden, mit
        denen Daten gesammelt werden, sind im folgenden erklärt.
      </p>
      <h4><b>a) Bewerbungen</b></h4>
      <p>
        Sie haben die Möglichkeit sich über unsere Website im Bereich
        Karriere zu bewerben. Hierzu erheben wir von Ihnen personenbezogene
        Kontaktdaten, wozu insbesondere Ihr Name, der Lebenslauf, das
        Bewerbungsschreiben und weitere durch Sie zur Verfügung gestellte
        Inhalte zählen. Ihre persönlichen Bewerbungsdaten werden
        ausschließlich zu Zwecken erhoben, gespeichert, verarbeitet und
        genutzt, die im Zusammenhang mit Ihrem Interesse an einer aktuellen
        oder zukünftigen Beschäftigung bei uns und der Bearbeitung Ihrer
        Bewerbung stehen. Ihre Online-Bewerbung wird nur von den relevanten
        Ansprechpartnern bei uns bearbeitet und zur Kenntnis genommen. Alle
        mit der Datenverarbeitung betrauten Mitarbeiter sind verpflichtet,
        die Vertraulichkeit Ihrer Daten zu wahren. Rechtsgrundlage für die
        Datenerhebung ist § 26 BDSG 2018.
      </p>
      <h4><b> b) Cookies und vergleichbare Technologien</b></h4>
      <p>
        Cookies sind kleine Textdateien, welche in Ihrem Internetbrowser
        gespeichert werden. Von uns werden Cookies und vergleichbare
        Technologien dazu genutzt, herauszufinden welche Webseiten,
        Werbungen, oder Features Sie sich ansehen und welche Spiele Sie
        spielen. Diese Daten unterstützen uns dabei, Ihren Einkaufswagen
        nachzuverfolgen, die Effizienz unserer Werbung zu beurteilen,
        sicherzustellen, dass Sie nicht wiederholt dieselbe Werbung sehen
        und um auf anderem Wege ihr Spielerlebnis zu fördern und zu
        verbessern. Es ist Ihnen möglich die Art der erlaubten Cookies
        einzuschränken, Cookies generell zu deaktivieren oder Ihren Browser
        so einzustellen, dass er Sie warnt, wenn Cookies übermittelt werden.
        Um Hinweise zur Verwaltung Ihrer Cookie-Voreinstellungen zu
        erhalten, bitten wir Sie, die Anleitung zu dem von Ihnen genutzten
        Browser zu nutzen. Des Weiteren setzen wir ebenfalls vergleichbare
        Technologien zu ähnlichen Zwecken wie Cookies ein. Wir nutzen unter
        anderem Zählpixel oder Clear GIFs, um Daten über Ihre Nutzung
        unserer Produkte und Ihre Reaktion auf unsere Marketing-E-Mails zu
        erheben. Wir benutzen Internet-Logdateien (die technische Daten wie
        Ihre IP-Adresse enthalten), um technische Probleme zu beheben, den
        Traffic auf unseren Produkten zu überwachen, unsere
        Nutzervereinbarung durchzusetzen und Betrugsversuche zu erkennen und
        zu unterbinden. Weitere Beispiele sind HTML 5-Cookies,
        Geräte-Fingerprints, Silverlight-Anwendungsspeicher und
        "Flash-Cookies". Sollten Sie Flash-Cookies entfernen oder ihre
        Installation unterbinden wollen, passen Sie Ihre Voreinstellungen in
        den Datenschutzeinstellungen auf der Macromedia-Webseite
        unter&nbsp;<a
        href="https://www.macromedia.com/support/documentation/de/flashplayer/help/"
      >www.macromedia.com/support/documentation/de/flashplayer/help/</a
      > an. Zurzeit reagieren wir nicht auf Do Not Track-Signale (DNT).
        Sobald es einen einheitlichen DNT-Standard gibt, werden wir diesen
        ggf. übernehmen. Bis dahin können Sie bestimmte Trackingtypen, wie
        abgestimmte Werbung und bestimmte Analysetools ablehnen, indem Sie
        Anpassungen und Ihren Cookie-Einstellungen vornehmen.
      </p>
      <p>
        Zusätzlich verwenden wir in unseren Produkten Analyse-,
        Advertising-, Anti-Cheat- und Anti-Betrugs-Technologien, welche
        dieselben Cookies und vergleichbare Technologien nutzen. Diese
        Technologien werden nachfolgend beschrieben.
      </p>
      <h4><b> c) Technologien und Tools für die Analyse</b></h4>
      <p>
        Um Daten über Ihre Nutzung unserer Produkte, Ihren Computer oder Ihr
        Gerät sowie den Zustand unserer Produkte zu erheben, werden von uns
        eigene Spiel-Server und interne sowie Analyse-Technologien von
        Drittanbietern verwendet.
      </p>
      <p>
        Wenn Sie unsere Produkte nutzen, kann Nurogames zum Beispiel Daten
        auf Ihrem Gerät oder Computer erheben und speichern. Diese Daten
        umfassen technische und andere, darauf bezogene Informationen über
        Ihren Computer oder Ihr Gerät und Ihr Betriebssystem (wie Ihre
        IP-Adresse und die Gerätekennung) sowie Daten über Ihre Nutzung
        bestimmter Funktionen, Systeminteraktionen, Gameplay- und
        Nutzungsstatistiken &nbsp;und Hardware-Peripherie. Wenn Sie ein
        Spiel offline spielen, werden diese Daten auf Ihrem Gerät
        gespeichert und an Nurogames übermittelt, sobald sich Ihr Gerät mit
        dem Internet verbindet. Sofern Sie Online-Dienste in Anspruch
        nehmen, kann Nurogames zudem Ihre Persona und/oder statistische
        Daten über den Spielverlauf (einschließlich Ergebnisse,
        Platzierungen und Leistungen) erheben, nutzen, speichern, übertragen
        und öffentlich anzeigen oder Inhalte identifizieren, die von Ihnen
        erstellt und mit anderen Spielern geteilt werden.
      </p>
      <p>
        In unsere Produkte integrierte Analyse-Dienste von Drittanbietern
        können die Daten, die sie in Verbindung mit den Diensten von
        Nurogames erheben, mit Daten kombinieren, die sie unabhängig bereits
        auf unterschiedlichen Webseiten erhoben haben. Viele dieser
        Unternehmen erheben und nutzen Daten im Rahmen ihrer eigenen
        Datenschutzrichtlinien. Eine Liste der Analyse-Tools von
        Drittanbietern, die in unseren Produkten vertreten sind, sowie
        Informationen zu deren Deaktivierung (soweit möglich) finden Sie auf <a href="https://privacyappendix.nurogames.com/"
        >privacyappendix.nurogames.com</a>.
      </p>
      <h4><b> d) Technologien und Tools für das Advertising</b></h4>
      <p>
        Diverse Produkte unsererseits verwenden Advertising-Technologien,
        die Cookies, Zählpixel, Web-Beacons, Clear GIFs und ähnliche
        Technologien wie Identifikatoren, einsetzen, um Ihnen
        maßgeschneiderte Angebote zukommen zu lassen. Von diesen
        Technologien können einige Ihr Verhalten auf unterschiedlichen
        Webseiten, Mobile-Apps und Geräten synchronisieren und verbinden.
        Dementsprechend können Werbeanzeigen an Ihre Interessen angepasst
        werden. Beispielsweise ist es möglich, dass sie ein Angebot für ein
        Spiel erhalten, von dem wir denken, dass es Ihnen gefallen könnte.
        Äußern Sie selbst Interesse an einem Spiel, erhalten Sie zu einem
        späteren Zeitpunkt möglicherweise eine Werbung für dieses Spiel oder
        ähnliche Produkte auf Webseiten von Nurogames oder von
        Drittanbietern. Andere Advertising-Technologien im Spiel ermöglichen
        den vorübergehenden Upload von Werbung auf Ihren Internetbrowser, in
        Ihr Spiel, oder auf Ihr Mobilgerät, die zu einem späteren Zeitpunkt
        ersetzt wird, wenn Sie online sind.
      </p>
      <p>
        Damit wir die Möglichkeit haben, für Sie relevante Werbung
        bereitzustellen und deren Ergebnisse zu verfolgen bzw. ihre
        Effektivität zu beurteilen, erheben und Nutzen diese Technologien
        Daten. Neben Identifikatoren enthalten diese Daten:
      </p>
      <ul>
        <li>die Anzeigedauer einer Werbung;</li>
        <li>
          bereitgestellte Werbungen und die jeweilige Position der Anzeige;
        </li>
        <li>IP-Adresse, Domain-Typ, Größe der Werbung; und</li>
        <li>die Reaktion auf eine Werbung.</li>
      </ul>
      <p>
        Advertising-Technologien von Drittanbietern können die im
        Zusammenhang mit der Bereitstellung einer Werbung über unsere
        Produkte erhobenen Daten mit anderen Daten kombinieren, die sie
        unabhängig bereits auf unterschiedlichen Webseiten gesammelt haben.
        Ein Großteil dieser Unternehmen erhebt und nutzt Daten im Rahmen
        seiner eigenen Datenschutzrichtlinien. &nbsp;Eine Liste von
        Advertising-Unternehmen, die ihre eigenen Netzwerke auf unseren
        Webseiten, unseren Online- und/oder Mobile-Produkten und/oder
        Diensten betreiben, sowie Informationen zu deren Deaktivierung
        (soweit möglich) finden Sie auf <a href="https://privacyappendix.nurogames.com/"
        >privacyappendix.nurogames.com</a>.
      </p>
      <p>
        Sollten Sie mehr über die Vorgehensweise dieser Anbieter erfahren
        wollen oder falls Sie zielgerichtete Werbung von Drittanbietern
        deaktivieren möchten, besuchen Sie <a href="http://www.aboutads.info/choices"
        >www.aboutads.info/choices</a> oder <a href="http://www.youronlinechoices.eu/"
        >www.youronlinechoices.eu</a>. Hinweis: Eine Deaktivierung geht nicht mit einer Deaktivierung
        von sämtlicher Werbung einher. Die Werbung ist dadurch
        möglicherweise nur nicht auf Ihre Interessen abgestimmt.
      </p>
      <h4><b>e) Technologien zur Prävention von Cheating und Betrug</b></h4>
      <p>
        Es ist das Ziel von Nurogames, eine faire und sichere Spielumgebung
        für sämtliche Spieler zu gewährleisten. Sobald Sie sich ein
        Nurogames-Konto erstellen, sich bei einem unserer Produkte anmelden
        oder einen Kauf abschließen, verwenden wir oder Drittanbieter
        möglicherweise Cookies und ähnliche Technologien oder erheben Daten
        über Ihren Computer oder Ihr Gerät, um zur Sicherheit,
        Betrugsvermeidung, und für Authentifizierungszwecke einen
        "Fingerabdruck" oder "Hash" von Ihrem Gerät oder deren Komponenten
        zu erzeugen. Außerdem können wir in unseren Produkten Technologien
        zur Prävention von Cheating einschließlich automatisierte
        Betrugserkennungs- und Missbrauchsalgorithmen verwenden. Sollten Sie
        der Meinung sein, dass Ihr Zugriff auf unsere Produkte und/oder
        Dienste durch diese Technologien fälschlicherweise blockiert wurde,
        kontaktieren sie uns bitte über <strong>info@nurogames.com</strong>.
      </p>
      <h3><b>4. Speicherort ihrer Daten</b></h3>
      <p>
        Die von uns erhobenen personenbezogenen Daten können für die in
        dieser Datenschutz- und Cookie-Richtlinie dargelegten Zwecke in
        Deutschland oder jedem anderen Land gespeichert und verarbeitet
        werden, in dem Nurogames, Nurogames Tochterunternehmen oder von
        Nurogames beauftragte Dritte tätig sind. Dritte und Drittanbieter
        sind unter dem Privacy-Shield-Abkommen zertifiziert und bietet
        hierdurch eine Garantie, das europäische Datenschutzrecht
        einzuhalten, vergleiche <a href="http://www.privacyshield.gov">www.privacyshield.gov</a>.
      </p>
      <p>
        Bitte beachten Sie, dass durch die Nutzung unserer Produkte Ihre
        personenbezogenen Daten an Empfänger in den USA oder in anderen
        Ländern übertragen werden können, welche nicht über das gleiche
        Level and Datenschutz wie die Gesetze Ihres Wohnsitzes oder Ihrer
        Staatsangehörigkeit verfügen.
      </p>
      <h3><b>5. Die Art und Weise der Datennutzung</b></h3>
      <p>
        Ihre personenbezogenen und nicht-personenbezogenen Daten werden von
        uns individuell und kombiniert auf folgende Weise verwendet:
      </p>
      <p>
        Zur Durchführung unserer Geschäfte und zur Verbesserung und
        Personalisierung Ihres Spielerlebnisses, einschließlich:
      </p>
      <ul>
        <li>
          Betrieb, Entwicklung und Verbesserung unserer Spiele und Dienste;
        </li>
        <li>
          Einrichtung und Aufrechterhaltung von Konten, bei denen Sie sich
          anmelden;
        </li>
        <li>
          Bereitstellung von Replays für Sie und andere Spieler über
          Zuschauer-Modi;
        </li>
        <li>
          Ermöglichung des Teilens von Inhalten in sozialen Netzwerken;
        </li>
        <li>
          Bereitstellung personalisierter Empfehlungen zu Produkten und/oder
          Features von Nurogames;
        </li>
        <li>
          Aktivierung oder Authentifizierung Ihrer Spiele und Speicherung
          Ihrer Spielfortschritte und Statistiken;
        </li>
        <li>
          Erstellen von Online-Bestenlisten und Ermöglichung von
          Online-Matchmaking;
        </li>
        <li>
          Bereitstellung von Werbeanzeigen und Beurteilung der Effektivität;
        </li>
        <li>
          Bereitstellung von Spielerlebnissen, die Ihnen gefallen könnten;
        </li>
        <li>Erfassen des Zustandes unserer Dienste;</li>
        <li>
          Hilfe bei der Suche nach Freunden und der Benachrichtigung von
          Freunden über ein Nurogames-Produkt;
        </li>
        <li>
          Berücksichtigung Ihrer Voreinstellung und Bereitstellung
          dynamischer Inhalte;
        </li>
        <li>Einrichtung von Treueprogrammen;</li>
        <li>Bereitstellung von Software-Updates;</li>
        <li>
          Ermöglichung des Gameplays auf mehreren Geräten, sofern verfügbar;
        </li>
        <li>Identifikation und Behebung von Bugs und anderen Fehlern;</li>
        <li>
          Gewährleistung der Sicherheit und Fairness unserer Dienste,
          Beilegung von Streitigkeiten, Erkennung und Vermeidung von
          Betrugs- und Missbrauchsversuchen, die Befolgung geltender Gesetze
          und die Durchsetzung unserer Vereinbarungen und Richtlinien.
        </li>
      </ul>
      <p>Bereitstellung von Unterstützung, einschließlich:</p>
      <ul>
        <li>
          Einholung Ihrer Meinung anhand von Fragebögen oder Umfragen;
        </li>
        <li>
          Verwaltung und Versendung von Bestätigungen und wichtigen
          Informationen über Ihr Konto, Produkte, Käufe, Abonnements und
          Garantien;
        </li>
        <li>
          Identifizierung und Behebung von Problemen mit Ihrem Konto oder
          Spielen; und
        </li>
        <li>
          Kommunikation mit Ihnen und Reaktion auf Ihre spezifischen
          Anfragen oder Fragen.
        </li>
      </ul>
      <p>
        Personalisierung unserer Kommunikation mit Ihnen, einschließlich:
      </p>
      <ul>
        <li>
          Bereitstellung von personalisierter Werbung und zielgerichtetem
          Marketing, Service-Updates und Werbeangeboten;
        </li>
        <li>Bereitstellung von Empfehlungen für Sie; und</li>
        <li>
          Bereitstellung von Angeboten und/oder Informationen zu Spielen,
          die Ihnen gefallen können.
        </li>
      </ul>
      <p>
        Wir speichern die erhobenen Daten, solange dies für die
        Bereitstellung unserer Produkte und Dienste erforderlich ist, und
        können diese Informationen auch über diesen Zeitraum hinaus
        speichern, wenn dies aus rechtlichen, betrieblichen oder anderen
        legitimen Gründen erforderlich ist.
      </p>
      <h3><b>6. Schützung ihrer personenbezogenen Daten</b></h3>
      <p>
        Die Sicherheit der Daten unserer Spieler hat für Nurogames einen
        enorm hohen Stellenwert und wir unternehmen zahlreiche Maßnahmen zu
        deren Schutz. Dennoch ist keine Sicherheitsmaßnahme zu 100 % sicher.
        Wir können daher die Sicherheit Ihrer Daten nicht garantieren und
        übernehmen keine Verantwortung für die unautorisierte Nutzung bzw.
        den unautorisierten Zugriff auf Daten unter unserer Kontrolle.
      </p>
      <h3><b>7. Weitergabe von Daten an Dritte</b></h3>
      <p>
        Ohne Ihr Einverständnis geben wir keine Daten an Dritte für deren
        eigene Nutzung weiter, mit denen Sie persönlich identifiziert werden
        können (beispielsweise Ihren Namen, Ihre Postanschrift oder Ihre
        E-Mail-Adresse), sofern dies nicht aus rechtlichen Gründen
        erforderlich ist oder wir der Ansicht sind, dass die Weitergabe
        erforderlich ist, um unsere Rechte durchzusetzen, unser Eigentum,
        unseren Geschäftsbetrieb oder unsere Spieler und Dritte zu schützen.
        Wir übermitteln anonyme oder aggregierte Informationen oder andere
        Daten an Dritte, durch die Sie nicht persönlich identifiziert werden
        können, zum Beispiel die Präsentation von Trends hinsichtlich der
        Nutzung unserer Produkte oder Ihre Persona und Spielstatistiken für
        die Erstellung von Bestenlisten.
      </p>
      <p>
        Wir setzen Drittanbieter in und außerhalb Ihres Wohnsitzlandes
        (einschließlich außerhalb des Europäischen Wirtschaftsraums (EWR))
        ein, um in unserem Auftrag personenbezogene Daten zu verschiedenen
        Zwecken zu erheben oder zu verarbeiten, beispielsweise für die
        Versendung von E-Mails, die Verarbeitung von Kreditkartenzahlungen
        oder die Durchführung von Marktanalysen. Wenn unsere Dienstleister
        oder Drittanbieter personenbezogene Daten erheben oder erhalten,
        sind sie verpflichtet, diese nur in unserem Auftrag und für die
        Zwecke dieser Richtlinie zu verwenden.
      </p>
      <p>
        Auch mit Einwilligung geben wir wissentlich keine personenbezogenen
        Daten von Personen unter 18 Jahren an unabhängige Dritte weiter.
      </p>
      <p>
        Bei einer Reorganisation, Auflösung, Fusion, Verkauf bzw. Insolvenz
        können wir alle Daten, die wir erheben, an relevante Dritte
        transferieren und werden dazu Ihr Einverständnis einholen, falls
        dies gesetzlich vorgeschrieben ist.
      </p>
      <p>
        Es werden von uns keine Daten kontrolliert, die von Ihrem Browser im
        Rahmen Ihrer normalen Internetaktivitäten an Dritte, wie
        Advertising-Netzwerke und Analyse-Unternehmen gesendet werden. Sind
        Technologien von Drittanbietern oder Social Tools in unsere Produkte
        integriert, können diese Drittanbieter Daten sammeln, wenn Sie
        unsere Produkte nutzen. Ein Beispiel hierfür ist der "Gefällt
        mir"-Button von Facebook.
      </p>
      <h3><b>8. Service von Drittanbietern</b></h3>
      <p>
        Es ist möglich, dass unsere Webseiten und Produkte
        Advertising-Dienste oder Dienste von Drittanbietern (wie Links zu
        den Webseiten von Drittanbietern oder den "Gefällt-mir"-Button von
        Facebook) enthalten. Folgen Sie diesen Links oder einer Werbung,
        verlassen Sie die Nurogames-Webseite und besuchen die von Ihnen
        gewählte Seite. Wenn Sie die Webseite eines Drittanbieters besuchen
        oder den Dienst eines Drittanbieters nutzen, empfehlen wir Ihnen die
        Datenschutzrichtlinie des jeweiligen Anbieters zu lesen.
      </p>
      <h3><b>9. Kinder</b></h3>
      <p>
        Es ist Nurogames äußerst wichtig die Online-Privatsphäre von Kindern
        zu schützen. Viele Online- oder Mobile-Produkte und Dienste von
        Nurogames sind für eine breite Anwenderschaft gedacht und erheben
        daher nicht bewusst personenbezogene Daten von Kindern. Sobald sich
        ein Nutzer als ein Kind identifiziert: (1) Ermöglichen wir keine
        Eingabe personenbezogener Daten, (2) Blocken oder Sperren wir den
        Zugang für das Kind zu Diensten, wie der Chat-Funktion; (3) Erheben
        wir bestimmte Daten nur für begrenzte Zwecke, und/oder (4) Holen für
        die Nutzung der personenbezogenen Daten gemäß geltender Gesetze die
        Einwilligung der Eltern oder Erziehungsberechtigten ein. Als Kinder
        bezeichnen wir alle Personen unter 13 Jahren oder unterhalb des
        Mindestalters in der jeweiligen Region, sollte dieses höher als 13
        Jahre ist. Es ist möglich, dass Ihr Kind über Mobile-Apps lokale
        Benachrichtigungen im Spiel erhält, welche keine personenbezogenen
        Daten erheben.
      </p>
      <p>
        Es ist zu beachten, dass im Falle Ihrer Einwilligung für Ihr Kind
        zur Nutzung der Online- oder Mobile-Produkte und -Dienste von
        Nurogames, auch der Zugang zu Kommunikationsdiensten wie E-Mail,
        Online-Gruppen mit einem breiten Publikum und Instant Messengers
        eingeschlossen ist und Ihr Kind grundsätzlich mit Nutzern sämtlicher
        Altersgruppen kommunizieren und an diese durchaus personenbezogene
        Daten weitergeben kann.
      </p>
      <p>
        Sollten Sie als Elternteil Ihre Einwilligung widerrufen wollen, die
        über Ihr Kind erhobene Daten überprüfen oder diese löschen lassen
        wollen, können Sie den zuständigen Datenschutzbeauftragten
        kontaktieren, an:&nbsp;Datenschutzbeauftragter, Nurogames GmbH,
        Schaafenstraße 25, 50676 Köln schreiben oder über <strong>info@nurogames.com</strong> kontaktieren.
      </p>
      <p>
        Die erhobenen Daten werden im Einklang mit den Bestimmungen dieser
        Richtlinie für Datenschutz und Cookies gespeichert. Im Falle
        wesentlicher Änderungen hinsichtlich der Nutzung, Erhebung oder
        Weitergabe der personenbezogenen Daten Ihres Kindes unsererseits,
        informieren wir Sie per E-Mail oder mittels einer Benachrichtigung
        im Spiel, um die entsprechende Einwilligung für die neue Nutzung der
        personenbezogenen Daten Ihres Kindes einzuholen.
      </p>
      <p>
        Unter keinen Umständen machen wir die Teilnahme eines Kindes an
        Aktivitäten wie Wettbewerben, von der Angabe von mehr
        personenbezogenen Daten als für die Teilnahme an der Aktivität
        erforderlich, abhängig. Wir können auf diversen Seiten die Teilnahme
        eines Kindes unabhängig von einer Einwilligung vollständig
        untersagen.
      </p>
      <h3><b>10. Ihre Entscheidungsmöglichkeiten</b></h3>
      <p>
        Wichtige Entscheidungsmöglichkeiten hinsichtlich der Erhebung und
        Nutzung von Daten überlassen wir Ihnen. Beispielsweise ist es Ihnen
        möglich Ihre Konto-Informationen und Voreinstellungen zu
        aktualisieren. Des Weiteren können Sie entscheiden, ob Sie
        Marketing-E-Mails von Nurogames erhalten oder abbestellen möchten
        und Sie haben die Möglichkeit zu entscheiden, ob Sie Ihre Daten an
        ausgewählte Partner von Nurogames weitergeben möchten. Möchten Sie
        Ihre Einwilligung zu einem beliebigen Zeitpunkt widerrufen,
        kontaktieren Sie den zuständigen Datenschutzbeauftragten, Nurogames
        GmbH, schreiben Sie an Schaafenstraße 25, 50676 Köln oder über <strong>info@nurogames.com</strong>.
      </p>
      <p>
        Sie haben die Möglichkeit Ihr Nurogames-Konto zu deaktivieren oder
        die mit Ihrem Konto verknüpften personenbezogenen Daten zu löschen.
        Hierdurch haben Sie keinen Zugriff mehr auf die Spiele oder Dienste
        von Nurogames, die mit Ihrem Konto verknüpft sind. Bitte beachten
        Sie, dass Nurogames Daten zur Beilegung von Streitigkeiten, zur
        Durchsetzung unserer Nutzervereinbarungen, zum Schutz unserer Rechte
        oder wegen technischer und rechtlicher Auflagen und Einschränkungen
        in Zusammenhang mit der Sicherheit, der Integrität und dem Betrieb
        unserer Produkte aufbewahren kann. Andernfalls speichern wir Ihre
        personenbezogenen Daten, solange dies für die Bereitstellung unserer
        Dienste, die Entwicklung und Verbesserung unserer Produkte, aus
        rechtlichen Gründen und für unseren Geschäftsbetrieb erforderlich
        ist.
      </p>
      <p>
        Sie können auf Ihre gespeicherten personenbezogenen Daten zugreifen.
        Wenden Sie sich dazu an <a href="https://help.nurogames.com/">help.nurogames.com</a>. Um
        Ihre Identität zu verifizieren, können wir Sie um bestimmte
        personenbezogene Daten bitten, bevor wir eine Anfrage Ihrerseits
        bearbeiten. Sie können gemäß lokalem Recht zusätzliche Rechte haben,
        einschließlich des Rechts, die Löschung bzw. Portierung
        personenbezogener Daten zu fordern und das Recht, die Verarbeitung
        von Daten zu verbieten oder einzuschränken. Indem Sie <a href="https://help.nurogames.com/">help.nurogames.com</a> kontaktieren, können Sie in diesem Fall diese Rechte ausüben.
      </p>
      <p>
        Um bestimmte Tracking Technologien, wie Cookies, zu blockieren,
        können Sie auch Ihre Browser-Einstellungen anpassen.
      </p>
      <h3><b>11. Änderungen an unserer Richtlinie</b></h3>
      <p>
        Es ist uns möglich diese Richtlinie von Zeit zu Zeit zu ändern. Sie
        sollten sie daher bitte regelmäßig durchsehen. Sind wir aufgrund
        geltender Datenschutzgesetze verpflichtet, Sie zu einem früheren
        Zeitpunkt zu informieren oder Ihre Zustimmung einzuholen, werden wir
        dies tun. Sie sehen, wann diese Richtlinie zuletzt aktualisiert
        wurde, indem Sie das Datum der "Letzten Aktualisierung" zu Beginn
        dieser Richtlinie überprüfen.
      </p>
      <h3><b>12. Kontaktaufnahme mit einer Datenschutzbehörde</b></h3>
      <p>
        Sollten Sie Bedenken darüber haben, wie wir Daten erheben und
        nutzen, bitten wir Sie, sich an uns zu wenden. Zusätzlich haben Sie
        das Recht, sich an Ihre lokale Datenschutzbehörde zu wenden.
        Wichtige Kontaktdaten für Datenschutzbehörden innerhalb der
        Europäischen Union finden sie auf <a
          href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
        >ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
      </p>
    </>
  );
}

export default German;
