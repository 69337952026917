import React from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import {ScrollRotate} from "react-scroll-rotate";

import borderGame from "../assets/Png/GraphicFrame.png";
import StoneRuneCircle from '../assets/Png/GraphicStoneCircle.png';

import Header from './../components/global/header';
import Footer from './../components/global/footer';

function TermsOfService() {
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const isBiggerMobile = useMediaQuery({ query: '(min-width: 992px)' })

  return (
    <>
      <Header/>
      <div className="terms-of-service-page">
        <ReactBootstrap.Container id="terms-of-service">
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="text-center">
                <h2>
                  {t('terms-of-service-title')}
                </h2>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="text-left">
                <h3>
                  {t('liability_for_content')}
                </h3>
                <div>
                  {t('text_one_tos')}
                </div>
                <div>
                  {t('text_two_tos')}
                </div>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="text-left">
                <h3>
                  {t('liability_for_links')}
                </h3>
                <div>
                  {t('text_three_tos')}
                </div>
                <div>
                  {t('text_four_tos')}
                </div>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="text-left">
                <h3>
                  {t('copyrights')}
                </h3>
                <div>
                  {t('text_five_tos')}
                </div>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="text-left">
                <h3>
                  {t('data_protection')}
                </h3>
                <div>
                  {t('text_six_tos')}
                </div>
                <div>
                  {t('text_seven_tos')}
                </div>
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="text-left">
                <div>
                  {t('source')}: S&K Rechtsanwälte <a href="https://www.streifler.de/">www.streifler.de</a>
                </div>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
        {
          isMobile ? (
            <></>
          ) : (
            <>
              <div className="corner-stone-rune-tos">
                <ScrollRotate from={0} to={360}>
                  <img src={StoneRuneCircle} alt="Stone Runes"/>
                </ScrollRotate>
              </div>
              {/*<div className="frame-game tos-border">
                <img src={borderGame} alt="Border"/>
              </div>*/}
            </>
          )
        }
      </div>
      <Footer/>
    </>
  );
};

export default TermsOfService;
