import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import Scrollspy from 'react-scrollspy';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import ActivePoint from './../../assets/svg/mobile/activePoint.svg';
import UnactivePoint from './../../assets/svg/mobile/unactivePoint.svg';

function Header(){
  const [isHome, setIsHome] = useState(false);
  const [isPlatform, setIsPlatform] = useState(false);
  const [isTrailer, setIsTrailer] = useState(false);
  const [isScreenshots, setIsScreenshots] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const { t, i18n } = useTranslation();
  //const ref = useRef();

  const changeLanguageGerman = () =>
  {
    i18n.changeLanguage("de")
    localStorage.setItem("language", "de");
  }

  const changeLanguageEnglish = () =>
  {
    i18n.changeLanguage("en")
    localStorage.setItem("language", "en");
  }

  const changeLanguageFrench = () =>
  {
    i18n.changeLanguage("fr")
    localStorage.setItem("language", "fr");
  }

  const changeLanguageItalian = () =>
  {
    i18n.changeLanguage("it")
    localStorage.setItem("language", "it");
  }

  const changeLanguageSpanish = () =>
  {
    i18n.changeLanguage("es")
    localStorage.setItem("language", "es");
  }

  const checkMenu = () => {
    const openMenu = document.querySelector(".navbar-collapse.collapse.show");

    if(typeof(openMenu) != 'undefined' && openMenu != null){
      document.querySelector(".navbar-toggler").click();

      setIsMobileMenuOpen(false);

      setTimeout(() => {
        window.history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
      }, 5);
    } else {
      setTimeout(() => {
        window.history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
      }, 5);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
    }, 5);
  }, []);

  const scrollEffectNavigation = () => {
    const listElement = document.querySelectorAll("#basic-nav-bar > div > ul > li")
    if (listElement[0].classList.contains('is-current')) {
      setIsPlatform(true);
      setIsTrailer(false);
      setIsScreenshots(false);
      setIsDescription(false);
    }
    if (listElement[1].classList.contains('is-current')) {
      setIsPlatform(false);
      setIsTrailer(true);
      setIsScreenshots(false);
      setIsDescription(false);
    }
    if (listElement[2].classList.contains('is-current')) {
      setIsPlatform(false);
      setIsTrailer(false);
      setIsScreenshots(true);
      setIsDescription(false);
    }
    if (listElement[3].classList.contains('is-current')) {
      setIsPlatform(false);
      setIsTrailer(false);
      setIsScreenshots(false);
      setIsDescription(true);
    }
  };

  const backToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 1000);
  }

  return (
    <>
      <ReactBootstrap.Navbar expand="lg" fixed="top" className={isMobileMenuOpen ? "navbar-extra" : ""}>
        {/*ref={ref}*/}
        <ReactBootstrap.Container fluid={true}>
          {
            window.location.pathname.length > 1 ? (
              <>
                <Link to="/" className="navigation-mobile-home" onClick={backToTop}>
                  {t('home')}
                </Link>
              </>
            ) : (
              <>
                {/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobileMenu" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                  <span className="navbar-toggler-icon"></span>
                </button>*/}
                <ReactBootstrap.Navbar.Toggle aria-controls="basic-nav-bar" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}/>
              </>
            )
          }
          <ReactBootstrap.Navbar.Collapse id="basic-nav-bar">
            <ReactBootstrap.Nav className="justify-content-end" style={{ width: "100%" }}>
              {/*
                isMobile ? (
                  <></>
                ) : (
                  <>
                    <ReactBootstrap.Nav.Link href="#">
                      <i className="fa-brands fa-instagram"></i>
                    </ReactBootstrap.Nav.Link>
                    <ReactBootstrap.Nav.Link href="#">
                      <i className="fa-brands fa-twitter"></i>
                    </ReactBootstrap.Nav.Link>
                    <ReactBootstrap.Nav.Link href="#">
                      <i className="fa-brands fa-facebook-f"></i>
                    </ReactBootstrap.Nav.Link>
                  </>
                )
              */}
              {
                window.location.pathname.length > 1 ? (
                  <></>
                ) : (
                  <>
                    <Scrollspy items={['platforms', 'trailer', 'screenshots', 'description']} offset={0} onUpdate={scrollEffectNavigation} currentClassName="is-current">
                      <li>
                        <ReactBootstrap.Nav.Link href="#platforms" onClick={checkMenu}>
                          {
                            isMobile ? (
                              <>
                                <img src={isPlatform ? ActivePoint : UnactivePoint} alt="Point"/>
                                <span>
                                  {t('platforms')}
                                </span>
                              </>
                            ) : (
                              <>
                                {t('platforms')}
                              </>
                            )
                          }
                        </ReactBootstrap.Nav.Link>
                      </li>
                      <li>
                        <ReactBootstrap.Nav.Link href="#trailer" onClick={checkMenu}>
                          {
                            isMobile ? (
                              <>
                                <img src={isTrailer ? ActivePoint : UnactivePoint} alt="Point"/>
                                <span>
                                  {t('trailer')}
                                </span>
                              </>
                            ) : (
                              <>
                                {t('trailer')}
                              </>
                            )
                          }
                        </ReactBootstrap.Nav.Link>
                      </li>
                      <li>
                        <ReactBootstrap.Nav.Link href="#screenshots" onClick={checkMenu}>
                          {
                            isMobile ? (
                              <>
                                <img src={isScreenshots ? ActivePoint : UnactivePoint} alt="Point"/>
                                <span>
                                  {t('screenshots')}
                                </span>
                              </>
                            ) : (
                              <>
                                {t('screenshots')}
                              </>
                            )
                          }
                        </ReactBootstrap.Nav.Link>
                      </li>
                      <li>
                        <ReactBootstrap.Nav.Link href="#description" onClick={checkMenu}>
                          {
                            isMobile ? (
                              <>
                                <img src={isDescription ? ActivePoint : UnactivePoint} alt="Point"/>
                                <span>
                                  {t('description')}
                                </span>
                              </>
                            ) : (
                              <>
                                {t('description')}
                              </>
                            )
                          }
                        </ReactBootstrap.Nav.Link>
                      </li>
                    </Scrollspy>
                  </>
                )
              }
              {
                isMobile ? (
                  <></>
                ) : (
                  <ReactBootstrap.NavDropdown title={t('language')} id="basic-nav-dropdown">
                    <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageEnglish}>
                      EN
                    </ReactBootstrap.NavDropdown.Item>
                    <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageGerman}>
                      DE
                    </ReactBootstrap.NavDropdown.Item>
                    <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageFrench}>
                      FR
                    </ReactBootstrap.NavDropdown.Item>
                    <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageItalian}>
                      IT
                    </ReactBootstrap.NavDropdown.Item>
                    <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageSpanish}>
                      ES
                    </ReactBootstrap.NavDropdown.Item>
                  </ReactBootstrap.NavDropdown>
                )
              }
            </ReactBootstrap.Nav>
          </ReactBootstrap.Navbar.Collapse>
          {
            isMobile ? (
              <ReactBootstrap.Nav className="justify-content-end navigation-mobile" style={{ width: "100%" }}>
                {/*<ReactBootstrap.Nav.Link href="#">
                  <i className="fa-brands fa-instagram"></i>
                </ReactBootstrap.Nav.Link>
                <ReactBootstrap.Nav.Link href="#">
                  <i className="fa-brands fa-twitter"></i>
                </ReactBootstrap.Nav.Link>
                <ReactBootstrap.Nav.Link href="#">
                  <i className="fa-brands fa-facebook-f"></i>
                </ReactBootstrap.Nav.Link>*/}
                <ReactBootstrap.NavDropdown title={t('language')} id="basic-nav-dropdown">
                  <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageEnglish}>
                    EN
                  </ReactBootstrap.NavDropdown.Item>
                  <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageGerman}>
                    DE
                  </ReactBootstrap.NavDropdown.Item>
                  <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageFrench}>
                    FR
                  </ReactBootstrap.NavDropdown.Item>
                  <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageItalian}>
                    IT
                  </ReactBootstrap.NavDropdown.Item>
                  <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageSpanish}>
                    ES
                  </ReactBootstrap.NavDropdown.Item>
                </ReactBootstrap.NavDropdown>
              </ReactBootstrap.Nav>
            ) : (
              <></>
            )
          }
        </ReactBootstrap.Container>
      </ReactBootstrap.Navbar>
    </>
  )
}

export default Header;
