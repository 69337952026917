import React from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import {ScrollRotate} from "react-scroll-rotate";

import borderGame from "../assets/Png/GraphicFrame.png";
import StoneRuneCircle from '../assets/Png/GraphicStoneCircle.png';

import Header from './../components/global/header';
import Footer from './../components/global/footer';

function Error() {
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const isBiggerMobile = useMediaQuery({ query: '(min-width: 992px)' })

  return (
    <>
      <Header/>
        <div className="error-page">
          <ReactBootstrap.Container id="error">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="text-center">
                  <div>
                    <h2>
                      Error - 404
                    </h2>
                  </div>
                  <div>
                    <h2>
                      {t('Error-Title')}
                    </h2>
                  </div>
                </div>
                <div className="text-center">
                  <div>
                    {t('Error-text-one')}
                  </div>
                  {/*<div>
                    {t('Error-text-two')}
                  </div>*/}
                </div>
                {/*<div>
                  <div>
                    <Link to="/">
                      {t('back-link')}
                    </Link>
                  </div>
                </div>*/}
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
          {
            isMobile ? (
              <></>
            ) : (
              <>
                <div className="corner-stone-rune-error">
                  <ScrollRotate from={0} to={360}>
                    <img src={StoneRuneCircle} alt="Stone Runes"/>
                  </ScrollRotate>
                </div>
                {/*<div className="frame-game error-border">
                  <img src={borderGame} alt="Border"/>
                </div>*/}
              </>
            )
          }
        </div>
      <Footer/>
    </>
  );
};

export default Error;
