import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {useParams} from "react-router";

import './i18n';

import Header from './components/global/header';
import Footer from './components/global/footer';

import Main from './views/main';
import Error from './views/error';
import Imprint from './views/imprint';
import TermsOfService from './views/termsOfService';
import PrivacyPolicy from './views/privacyPolicy';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import {useEffect} from "react";

function App() {
  useEffect(() => {
    localStorage.setItem("language", "en");
  }, [])
  return (
    <>
      <Router>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/terms-of-service" element={<TermsOfService/>}/>
          <Route path="/imprint" element={<Imprint/>}/>
          <Route path="/" element={<Main/>}/>
          <Route path="*" element={<Error />}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
